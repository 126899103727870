<script lang="ts" setup>
import { onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import * as client from '@gabrielcam/api-client';
import { Entitlements } from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import {
  BadgeVariant,
  BreadcrumbTitles,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  PageNames,
  UserStatus,
} from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';
import { UserViewModel } from '@viewModels/userViewModel';

import BadgeComponent from '@components/BadgeComponent.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import SubHeader from '@components/SubHeader.vue';
import { TableColumn } from '@components/table/models/Table';
import TLSTable from '@components/table/TLSTable.vue';
import EmptyState from '@layouts/EmptyState.vue';
import ModalComponent from '@components/ModalComponent.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import { storeToRefs } from 'pinia';

const applicationStore = useApplicationStore();
const { adminMode } = storeToRefs(applicationStore);
const grantRoles = applicationStore.canUser(Entitlements.GRANT_ROLE, applicationStore.activeOrganisation!);
const revokeRoles = applicationStore.canUser(Entitlements.REVOKE_ROLE, applicationStore.activeOrganisation!);
const showInvite = grantRoles;
const showManageRoles = grantRoles || revokeRoles;

const users = ref<UserViewModel[]>([]);
const isLoading = ref<boolean>(true);
const isSubmitting = ref<boolean>(true);
const router = useRouter();

const showDeleteUserModel = ref(false);
const deleteUserModelFunc = ref<Function>(() => deleteUser);
const userName = ref('');

interface StatusBadge {
  label: UserStatus;
  variant: BadgeVariant;
}

enum TableHeaders {
  Name = 'Name',
  Email = 'Email',
  Roles = 'Roles',
  Status = 'Status',
  Actions = 'Actions',
}

onMounted(async () => {
  isLoading.value = true;
  users.value = await getUsersList();
  isLoading.value = false;
});

async function resendInvite(user: UserViewModel): Promise<void> {
  isSubmitting.value = true;
  try {
    await client.createInvitation({
      requestBody: {
        email: user.email,
        displayName: user.displayName,
        roles: user.roles,
      },
    });

    applicationStore.publishSuccessNotification({
      text: 'Successfully invited user.',
      autoCloseMs: 3000,
    });

    router.push({ name: PageNames.Users });

  } catch (error) {
    if (error instanceof client.ApiError) {
      // @ts-ignore
      applicationStore.publishErrorNotification({ text: error.body.error.message });
    } else {
      applicationStore.publishErrorNotification({ text: 'UNKNOWN ERROR' });
    }
  } finally {
    isSubmitting.value = false;
  }
}

/**
 * Retrieves a list of users for the active organisation, mapping roles and formatting user details.
 *
 * @returns {Promise<UserViewModel[]>} A promise that resolves to an array of user view models.
 */
async function getUsersList(): Promise<UserViewModel[]> {
  const org = applicationStore.activeOrganisation;
  // Fetch the list of users for the active organisation, filtering by person type.
  const res = await client.listUsers({ organisation: org!.id, type: client.UserTypes.PERSON });
  // Fetch the list of roles to map user roles to their corresponding names.
  const roles = (await client.listRoles()).data;

  // Transform the user data
  return res.data.map((user) => {
    // Map user roles to their corresponding names
    const userRoleNames = user.roles.map((role) =>
      roles.find((roleItem) => roleItem.id === role.id)?.shortName
    );

    // Remove duplicate role names
    const uniqueRoleNames = [...new Set(userRoleNames)];

    // Return the updated UserViewModel model
    return {
      id: user.id,
      status: user.status,
      email: user.email,
      displayName: `${user.forename} ${user.surname}`,
      roles: user.roles,
      rolesDisplay: uniqueRoleNames,
      isAdmin: user.isAdmin ?? false,
    };
  }) as UserViewModel[];
}

const columns: TableColumn[] = [
  {
    label: TableHeaders.Name,
    field: 'displayName',
    headerClasses: 'text--white-space-nowrap',
    width: '1%',
  },
  {
    label: TableHeaders.Email,
    field: 'email',
    headerClasses: 'text--white-space-nowrap',
    width: '45%',
  },
  {
    label: TableHeaders.Roles,
    field: 'rolesDisplay',
    headerClasses: '',
    width: '45%',
  },
  {
    label: TableHeaders.Status,
    field: 'status',
    headerClasses: 'status',
    width: '1%',
  },
  {
    label: TableHeaders.Actions,
    field: 'id',
    headerClasses: '',
    width: '1%',
  },
];

const table = reactive({
  columns: columns,
  totalRecordCount: 0,
  sortable: {
    order: 'id',
    sort: 'asc',
  },
});

async function closeDeleteConfirmModal(): Promise<void> {
  showDeleteUserModel.value = false;
}

async function showDeleteConfirmModal(row: client.User): Promise<void> {
  showDeleteUserModel.value = true;
  userName.value = row.displayName;

  deleteUserModelFunc.value = async (): Promise<void> => {
    await deleteUser(row);
  };
}

async function deleteUser(row: client.User): Promise<void> {
  await client.deleteUserById({ userId: row.id });

  await closeDeleteConfirmModal();

  applicationStore.publishSuccessNotification({
    text: `Successfully deleted ${row.displayName}.`,
    autoCloseMs: 3000,
  });

  // Refresh the user list after deletion
  isLoading.value = true;
  users.value = await getUsersList();
  isLoading.value = false;
}

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllUsers, active: true },
];

/**
 * Mapping of user statuses. Each status badge has a label and a variant.
 */
const statusBadgeMap: Record<UserStatus, StatusBadge> = {
  [UserStatus.Active]: { label: UserStatus.Active, variant: BadgeVariant.Success },
  [UserStatus.Invited]: { label: UserStatus.Invited, variant: BadgeVariant.Warning },
  [UserStatus.Accepted]: { label: UserStatus.Accepted, variant: BadgeVariant.Info }
};
const getStatusBadge = (status: UserStatus): StatusBadge | undefined => {
  return statusBadgeMap[status];
};
</script>

<template>
  <SubHeader heading="Users"
             level="2">
    <template v-if="showInvite"
              #buttons>
      <ButtonComponent :is-block-btn="true"
                       :to="{ name: PageNames.UserInvitation }"
                       :variant="ButtonVariant.Dark">
        Invite User
      </ButtonComponent>
    </template>
  </SubHeader>

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <div class="main-wrapper">
      <TLSTable :columns="table.columns"
                :data="users"
                :is-loading="isLoading"
                :sortable="table.sortable"
                :total="table.totalRecordCount">
        <template #table-empty>
          <EmptyState heading-text="No users found"
                      :icon-name="IconName.UserGroupIcon"
                      :icon-style="IconStyle.Outline" />
        </template>

        <template #cell="{ row, column }">
          <div v-if="column.label === TableHeaders.Roles" class="d-flex gap-column-5">
            <div v-for="role in row.rolesDisplay" :key="role">
              <BadgeComponent :is-pill="true" :variant="BadgeVariant.Light">
                {{ role }}
              </BadgeComponent>
            </div>
          </div>

          <div v-if="column.label === TableHeaders.Status" class="d-flex justify-center">
            <BadgeComponent v-if="getStatusBadge(row.status)"
                            :is-pill="true"
                            :variant="getStatusBadge(row.status)?.variant">
              {{ getStatusBadge(row.status)?.label }}
            </BadgeComponent>
          </div>

          <div v-if="column.label === TableHeaders.Actions"
               class="d-flex gap-20">
            <ButtonComponent v-if="showManageRoles && (applicationStore.activeUser?.id !== row.id)"
                             :icon-name="IconName.WrenchIcon"
                             :icon-style="IconStyle.Outline"
                             :size="ButtonSize.Small"
                             :variant="ButtonVariant.Dark"
                             @click="() => router.push({ name: PageNames.UserManageRoles, params: { id: row.id } })">
              Manage Roles
            </ButtonComponent>

            <ButtonComponent v-if="applicationStore.activeUser?.id === row.id"
                             :icon-name="IconName.CogIcon"
                             :icon-style="IconStyle.Outline"
                             :size="ButtonSize.Small"
                             :variant="ButtonVariant.Dark"
                             @click="() => router.push({ name: PageNames.Account })">
              Your account
            </ButtonComponent>

            <ButtonComponent v-if="adminMode && !row.isAdmin && (applicationStore.activeUser?.id !== row.id)"
                             :icon-name="IconName.WrenchIcon"
                             :icon-style="IconStyle.Outline"
                             :size="ButtonSize.Small"
                             :variant="ButtonVariant.Dark"
                             @click="() => router.push({ name: PageNames.UserEdit, params: { id: row.id } })">
              Edit User
            </ButtonComponent>
            <ButtonComponent v-if="adminMode"
                             :icon-name="IconName.CogIcon"
                             :icon-style="IconStyle.Outline"
                             :size="ButtonSize.Small"
                             :variant="ButtonVariant.Dark"
                             @click="() => router.push({ name: PageNames.UserRoles, params: { id: row.id } })">
              Edit Roles
            </ButtonComponent>
            <ButtonComponent v-if="adminMode && !row.isAdmin"
                             :icon-name="IconName.TrashIcon"
                             :icon-style="IconStyle.Outline"
                             :size="ButtonSize.Small"
                             :variant="ButtonVariant.Danger"
                             @click="showDeleteConfirmModal(row)">
              Delete User
            </ButtonComponent>
            <ButtonComponent v-if="showInvite && row.status === UserStatus.Invited"
                             :icon-name="IconName.EnvelopeOpenIcon"
                             :icon-style="IconStyle.Outline"
                             :icon-position="IconPosition.Left"
                             :size="ButtonSize.Small"
                             :variant="ButtonVariant.Info"
                             @click="resendInvite(row)">
              Resend Invite
            </ButtonComponent>
          </div>
        </template>
      </TLSTable>
    </div>
  </ContainerCard>

  <!-- Delete User Modal -->
  <ModalComponent :visible="showDeleteUserModel && adminMode"
                  heading-title="Delete User"
                  @on-close="closeDeleteConfirmModal">
    <template #modal-content>
      <p>
        Are you sure you want to delete the account <span class="text--bold">{{ userName }}</span> this cannot be undone.
      </p>
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeDeleteConfirmModal">
        Cancel
      </ButtonComponent>
      <ButtonComponent :type="ButtonType.Submit"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Danger"
                       @click="deleteUserModelFunc()">
        Delete
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>
