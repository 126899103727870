/**
 * Extracts a meaningful error message from a given error object.
 *
 * If the error object has a 'body' property and the 'body' object has an 'error' property,
 * the function returns the 'message' property of the 'error' object. If not, it returns a default error message.
 *
 * @param error The error object to extract the message from.
 * @returns A meaningful error message.
 */
export function extractErrorMessage(error: unknown): string {
  // Check if the error object is not null or undefined and is of type object
  if (error && typeof error === 'object' && 'body' in error) {
    // Cast the error object to a type that has a 'body' property with an 'error' property
    const apiError = error as { body?: { error?: { message?: string } } };

    // Return the 'message' property of the 'error' object if it exists, otherwise return a default error message
    return apiError.body?.error?.message ?? 'An unknown error occurred. Please try again.';
  }

  // If the error object does not have a 'body' property, return a default error message
  return 'An unknown error occurred. Please try again.';
}
