import { Ref } from 'vue';
import { NotificationState } from '@viewModels/enums';

export interface Notification {
  text?: string;
  state: NotificationState;
  class: string;
}

/**
 * Sets a notification to be cleared after a specified timeout.
 *
 * @param currentNotification - A Ref to the notification object to be cleared.
 * @param timeoutMs - The time in milliseconds to wait before clearing the notification. Defaults to undefined.
 */
export function setTimeoutNotification(currentNotification: Ref<Notification | undefined>, timeoutMs?: number): void {
  if (timeoutMs == undefined) return;
  setTimeout(() => (currentNotification.value = undefined), timeoutMs);
}

/**
 * Returns the CSS class for a notification based on its state.
 *
 * @param state - The state of the notification.
 * @returns The CSS class for the notification. Can be 'notification', 'open notification-error', or 'open notification-success'.
 */
export function getCssClass(state: NotificationState): "notification" | "open notification-error" | "open notification-success" {
  if (state === NotificationState.Error) return 'open notification-error' as const;
  if (state === NotificationState.Success) return 'open notification-success' as const;
  return 'notification' as const;
}