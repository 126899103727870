<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useVideoStore } from '@stores/video';

import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';
import { ButtonVariant } from '@viewModels/enums';
import Pagination from '@components/Pagination.vue';
import ButtonComponent from '@components/ButtonComponent.vue';

const videoStore = useVideoStore();
const { videos, paginationOptions } = storeToRefs(videoStore);

const emit = defineEmits<{
  (e: 'onLoading'): void;
  (e: 'onLoaded'): void;
}>();

async function nextPage(): Promise<void> {
  emit('onLoading');
  paginationOptions.value.pageNumber++;
  await videoStore.getVideos(props.viewId);
  emit('onLoaded');
}

async function previousPage(): Promise<void> {
  emit('onLoading');
  paginationOptions.value.pageNumber--;
  if (paginationOptions.value.pageNumber < 0) paginationOptions.value.pageNumber = 1;
  await videoStore.getVideos(props.viewId);
  emit('onLoaded');
}

async function pageSizeChanged(): Promise<void> {
  emit('onLoading');
  paginationOptions.value.pageNumber = 1;
  await videoStore.getVideos(props.viewId);
  emit('onLoaded');
}

const props = defineProps({
  viewId: {
    type: String,
    required: false,
    default: '',
  },
});
</script>

<template>
  <Pagination>
    <template #pagination-dropdown>
      <v-select v-model="paginationOptions.pageSize"
                label="text"
                :options="[
                  { value: 12, text: '12 per page' },
                  { value: 24, text: '24 per page' },
                  { value: 36, text: '36 per page' },
                  { value: 48, text: '48 per page' },
                  { value: 60, text: '60 per page' },
                ]"
                :clearable="false"
                :searchable="false"
                :reduce="(value: any) => value.value"
                @option:selected="pageSizeChanged" />
    </template>
    <template #pagination-buttons>
      <ButtonComponent v-if="paginationOptions.pageNumber > 1"
                       :icon-style="IconStyle.Outline"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.ChevronLeftIcon"
                       :is-outline-btn="true"
                       :is-icon-btn="true"
                       :variant="ButtonVariant.Dark"
                       :disabled="paginationOptions.pageNumber <= 1"
                       @click="previousPage">
        Previous
      </ButtonComponent>
      <ButtonComponent v-if="videos.offset + paginationOptions.pageSize < videos.total_count"
                       :icon-style="IconStyle.Outline"
                       :icon-position="IconPosition.Right"
                       :icon-name="IconName.ChevronRightIcon"
                       :is-outline-btn="true"
                       :is-icon-btn="true"
                       :variant="ButtonVariant.Dark"
                       :disabled="videos.offset + paginationOptions.pageSize >= videos.total_count"
                       @click="nextPage">
        Next
      </ButtonComponent>
    </template>
    <template #pagination-info>
      <p>
        {{ videos.offset + 1 }} to
        {{ videos.offset + paginationOptions.pageSize > videos.total_count
          ? videos.total_count
          : videos.offset + paginationOptions.pageSize }}
        of {{ videos.total_count }} results
      </p>
    </template>
  </Pagination>
</template>
