<script lang="ts" setup>
import { computed, onMounted, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useApplicationStore } from '@stores/application';
const applicationStore = useApplicationStore();
const { activeUser } = applicationStore;

import dayjs from 'dayjs';
import 'dayjs/locale/en';
import moment from 'moment';

import * as client from '@gabrielcam/api-client';

import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import IrisViewer from '@components/viewer/IrisViewer.vue';
import { dateTimeFormat, timeFormat, dateFormat } from '@utils/date';
import { ViewerImage } from '@/types';

const route = useRoute();
const viewId: string = route.params['id'] as string;
const view = ref({}) as Ref<client.View>;
const allImages = ref([]) as Ref<ViewerImage[]>;
const availableDates = ref([]) as Ref<string[]>;
const loading = ref<boolean>(true);
const selectedDate = ref() as Ref<string>;

onMounted(async () => {
  loading.value = true;
  view.value = await client.getViewById({ viewId });
  // Get today's images first
  const latestImageDate = dayjs(view.value.lastCapturedUtc).format('YYYY-MM-DD');
  availableDates.value = [latestImageDate];
  selectedDate.value = latestImageDate;

  const startDate = dayjs(view.value.lastCapturedUtc).startOf('day').toISOString();
  const endDate = dayjs(view.value.lastCapturedUtc).endOf('day').toISOString();
  let images = (
    await client.listViewByIdImages({
      viewId,
      startDate,
      endDate,
      status: client.ImageStatus.COMPLETE,
    })
  ).data;
  if (images.length != 0) {
    allImages.value = images.filter((x) => x.capturedAt !== undefined);
    loading.value = false;
  }

  // Then get all images
  availableDates.value = (await client.listViewByIdCapturedDates({ viewId })).data;
  // TODO: This code causes the UI to become unresponsive, it is loading every image that has ever been taken for a View
  // long running views can have data in the 10s of thousands and it causes the browser to lag on occasion
  allImages.value = (await client.listViewByIdImages({ viewId, status: client.ImageStatus.COMPLETE })).data.filter(
    (x) => x.capturedAt !== undefined
  );

  if (images.length != 0) {
    selectedDate.value = availableDates.value.at(0) ?? '';
  }

  loading.value = false;
});

const imagesGroupedByDate = computed(() => {
  const grouped = new Map<string, ViewerImage[]>();
  allImages.value.forEach((image) => {
    if (image.capturedAt === undefined) return;
    image.formattedDateTime = dateTimeFormat(activeUser?.timezone).format(new Date(image.capturedAt));
    image.formattedTime = timeFormat(activeUser?.timezone).format(new Date(image.capturedAt));
    image.formattedDate = dateFormat(activeUser?.timezone).format(new Date(image.capturedAt));

    // TODO: The below needs to be changed to be aware of the current users Timezone
    // Images recorded at around within the users Timezone offset will be grouped on the wrong day
    // i.e. Image taken at 21/12/2024 @ 11pm UTC, should for a User in the Europe/Athens Timezone be grouped
    // to the 22/12/2024, as the local time is 2 hours ahead
    const localTime = moment(image.capturedAt).local();
    image.capturedAt = localTime.format('YYYY-MM-DD HH:mm:ss'); //TODO Move to a viewmodel mapper
    const capturedDate = localTime.format('YYYY-MM-DD');
    if (grouped.has(capturedDate)) {
      grouped.get(capturedDate)!.push(image);
      return;
    }
    grouped.set(capturedDate, [image]);
  });
  return grouped;
});
</script>

<template>
  <ContainerCard>
    <Loading v-if="loading" />

    <IrisViewer v-else
                :dates="availableDates"
                :images="imagesGroupedByDate"
                :init-date="availableDates[availableDates.length - 1]!"
                :title="view.name"
                :view="view" />
  </ContainerCard>
</template>
