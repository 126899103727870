<script setup lang="ts">
import { onMounted, Ref, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { storeToRefs } from 'pinia';

import { useVideoStore, Video } from '@stores/video';
import { ButtonVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Directory from '@components/directory/Directory.vue';
import ModalComponent from '@components/ModalComponent.vue';
import VideoDirectoryFilter from '@components/video/VideoDirectoryFilter.vue';
import VideoDirectoryPagination from '@components/video/VideoDirectoryPagination.vue';
import VideoPlayer from '@components/video/VideoPlayer.vue';
import VideoCard from '@components/cards/VideoCard.vue';
import HorizontalRule from '@components/HorizontalRule.vue';

const videoStore = useVideoStore();
const route = useRoute();
const { videos } = storeToRefs(videoStore);

const selectedFilters = ref({
  client: undefined,
}) as Ref<{ client: string | undefined }>;

const viewId = ref<string>();
const sortBy = ref<string | undefined>();

// Video Player Modal
const videoPlayerVisible = ref(false);
const videoPlayerUrl = ref<string>('');

// Delete Video Modal
const deleteVideoVisible = ref(false);
const videoToDelete = ref<string>();

watch(
  () => route.params['id'],
  async (viewId) => {
    await videoStore.getVideos(viewId as string, sortBy.value);
  }
);
async function updateSort(sortBy: string, sortOrder: string): Promise<void> {
  const sortValue = `${sortOrder}${sortBy}`;
  // Fetch sorted videos
  await videoStore.getVideos(viewId.value, sortValue);
  // console.log('Updated videos data:', videos.value);
}

onMounted(async () => {
  viewId.value = route.params['id'] as string | undefined;

  await videoStore.getVideos(viewId.value);
});

function closeVideoPlayerModal(): void {
  videoPlayerVisible.value = false;
}
function openVideoPlayer(video: Video): void {
  videoPlayerVisible.value = true;
  videoPlayerUrl.value = video.url ?? '';
}

// Open Delete Video Modal
function openDeleteVideoModal(video: Video): void {
  deleteVideoVisible.value = true;
  videoToDelete.value = video.viewName ?? video.name ?? video.id;
}
// Close Delete Video Modal
function closeDeleteVideoModal(): void {
  deleteVideoVisible.value = false;
}

// Delete Video
// TODO: Wire up Delete Video
async function deleteVideo(): Promise<void> {
  // await videoStore.deleteVideo(videoPlayerUrl.value);
}
</script>

<template>
  <ContainerCard>
    <VideoDirectoryFilter @select-client="(clientId: string) => (selectedFilters.client = clientId)"
                          @sort-view-by="updateSort" />

    <Directory :object-collection-reference="videos.data">
      <template #default="scope">
        <VideoCard :resource="scope.resource"
                   @play-video="openVideoPlayer(scope.resource)"
                   @delete-video="openDeleteVideoModal(scope.resource)" />
      </template>
    </Directory>

    <HorizontalRule />

    <VideoDirectoryPagination />
  </ContainerCard>

  <!-- Video Player Modal -->
  <ModalComponent :visible="videoPlayerVisible"
                  heading-title="Watch Video"
                  @on-close="closeVideoPlayerModal">
    <template #modal-content>
      <VideoPlayer :video-src="videoPlayerUrl" />
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeVideoPlayerModal">
        Close
      </ButtonComponent>
    </template>
  </ModalComponent>

  <!-- Delete Video Modal -->
  <ModalComponent :visible="deleteVideoVisible"
                  heading-title="Delete Video"
                  @on-close="closeDeleteVideoModal">
    <template #modal-content>
      <p>
        Are you sure you want to delete <span class="text--bold">{{ videoToDelete }}</span> video?
      </p>
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeDeleteVideoModal">
        Close
      </ButtonComponent>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Danger"
                       @click="deleteVideo()">
        Delete
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>
