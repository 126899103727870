import { onMounted, onUnmounted } from 'vue';

import { isMobileState, updateIsMobile } from './isMobile';

export function useMobileTooltip(): { isMobile: boolean; onShow: () => void; onHide: () => void } {
  let count = 0;

  const onShow = (): void => {
    if (count === 0) {
      document.body.classList.add('no-scroll');
    }
    count++;
  };

  const onHide = (): void => {
    count--;
    if (count === 0) {
      document.body.classList.remove('no-scroll');
    }
  };

  onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
    updateIsMobile();
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
  });

  return {
    isMobile: isMobileState.isMobile,
    onShow,
    onHide,
  };
}
