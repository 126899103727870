<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import * as client from '@gabrielcam/api-client';

import { convertToScheduleDto } from '@utils/ScheduleMapper';

import { useApplicationStore } from '@stores/application';
import { useScheduleServiceStore } from '@stores/schedule';
import { ButtonType, ButtonVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import CameraScheduleSettings from '@components/camera/CameraScheduleSettings.vue';
import HeaterScheduleSettings from '@components/camera/HeaterScheduleSettings.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import ButtonActions from '@layouts/ButtonActions.vue';

const isSubmitting = ref<boolean>(false);
const route = useRoute();
const cameraId = route.params['id'] as string;
const currentCamera = ref<client.Camera>();
const isLoading = ref<boolean>(true);
const fetchSettingsIsLoading = ref<boolean>(false);
const shadow = ref<client.Shadow>();
const applicationStore = useApplicationStore();
const scheduleServiceStore = useScheduleServiceStore();

const refreshTrigger = ref(false);


// Define the fetchCameraId function
async function fetchCameraId(): Promise<void> {
  isLoading.value = true;
  try {
    currentCamera.value = await client.getCameraById({ cameraId });
  } catch (error) {
    console.error('Error fetching camera data:', error);
  } finally {
    isLoading.value = false;
  }
}

// Using JSON.stringify to deeply compare the reported vs desired states
const reportedNotEqualToDesired = computed(() => {
  return JSON.stringify(shadow.value?.state.reported) !== JSON.stringify(shadow.value?.state.desired);
});

// Manual refresh logic for the button
const fetchSettings = async (): Promise<void> => {
  fetchSettingsIsLoading.value = true;

  // Toggle the `refreshTrigger` to notify the child component to refresh the camera schedule settings
  refreshTrigger.value = !refreshTrigger.value;

  try {
    await fetchCameraId();

    if (reportedNotEqualToDesired.value) {
      // Notify the user that changes are still pending
      applicationStore.publishErrorNotification({
        text: 'The camera may be offline, please try again later.',
        autoCloseMs: 3000,
      });
    } else {
      applicationStore.publishSuccessNotification({
        text: 'Settings refreshed.',
        autoCloseMs: 3000,
      });
    }
  } finally {
    fetchSettingsIsLoading.value = false;
  }
};

async function handleSubmit(): Promise<void> {
  const schedule = scheduleServiceStore.monthlySheduleSections.at(0); // handle ALL months only for now

  if (!schedule) return;

  const dto = convertToScheduleDto(schedule);
  var desired = shadow?.value?.state?.reported ?? {};
  desired['scheduleStartTime'] = dto.scheduleStartTime;
  desired['scheduleEndTime'] = dto.scheduleEndTime;
  desired['scheduleRate'] = dto.scheduleRate;
  desired['scheduleDays'] = dto.scheduleDays;

  isSubmitting.value = true;
  try {
    shadow.value = await client.updateCameraByIdShadow({
      cameraId,
      requestBody: {
        state: {
          desired: desired,
        },
      },
    });

    isSubmitting.value = false;

    applicationStore.publishSuccessNotification({
      text: 'Successfully updated camera.',
      autoCloseMs: 3000,
    });
  } catch (error: any) {
    if (error instanceof client.ApiError) {
      // @ts-ignore
      applicationStore.publishErrorNotification({ text: error.body.error.message });
    }
    applicationStore.publishErrorNotification({ text: 'UNKNOWN ERROR' });
    isSubmitting.value = false;
    return;
  }
}

// Get current camera data
onMounted(fetchCameraId);
</script>

<template>
  <section>
    <ContainerCard>
      <Loading v-if="isLoading" />

      <section v-else>
        <form>
          <CameraScheduleSettings :camera-id="cameraId" />
          <HeaterScheduleSettings :refresh-trigger="refreshTrigger" />

          <ButtonActions>
            <ButtonComponent :variant="ButtonVariant.Dark"
                             :is-block-btn="true"
                             :type="ButtonType.Button"
                             :loading="fetchSettingsIsLoading"
                             @click="fetchSettings">
              Refresh Settings
            </ButtonComponent>
            <ButtonComponent :variant="ButtonVariant.Dark"
                             :disabled="isSubmitting"
                             :is-block-btn="true"
                             @click="handleSubmit">
              Update Settings
            </ButtonComponent>
          </ButtonActions>
        </form>
      </section>
    </ContainerCard>
  </section>
</template>
