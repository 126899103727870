<script setup lang="ts">
import { useRoute } from 'vue-router';
import EditUserForm from '@components/account_forms/EditUserForm.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import SubHeader from '@components/SubHeader.vue';
import { BreadcrumbPaths, BreadcrumbTitles } from '@viewModels/enums';

const route = useRoute();
const userId: string = route.params['id'] as string;

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllUsers, to: BreadcrumbPaths.AllUsers },
  { title: BreadcrumbTitles.EditUser, active: true },
];
</script>

<template>
  <SubHeader heading="Roles & Permissions"
             level="2" />

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <EditUserForm :user-id="userId" />
</template>
