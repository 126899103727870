import { defineStore } from 'pinia';
import { getAuth, signOut, User } from 'firebase/auth';
import { useApplicationStore } from '@stores/application';
import { resetAllStores } from '@stores/util';
import * as client from '@gabrielcam/api-client';
import router from '@router/index';
import { PageNames } from '@viewModels/enums';

let refreshTokenInterval: ReturnType<typeof setTimeout> | undefined;
let expirationCheckInterval: ReturnType<typeof setInterval> | undefined;

/**
 * Pinia Session Management Store
 * =============================
 *
 * Overview
 * --------
 * Manages user authentication and session state, integrating Firebase Auth tokens, session timeouts, and auto-logout.
 *
 * Features
 * --------
 * - Initialises and manages OpenAPI tokens with Firebase Auth integration
 * - Periodically checks for token expiration, with automatic logout on session timeout
 * - Handles public view route tokens, allowing token refresh without authentication
 * - Provides logout functionality with full session cleanup, both automatic and manual
 *
 * Key Methods
 * -----------
 * - `startSession`: Initialises session on user login, sets OpenAPI token, and configures expiration checks
 * - `startSessionTimeout`: Sets up warning and logout timers based on session duration
 * - `clearSession`: Resets stores, performs Firebase sign-out, and redirects to log in
 * - `refreshTokenForPublicRoute`: Refreshes token for specific routes without full logout
 * - `logoutSession`: Resets authentication and redirects to log in with a custom message
 *
 * State Properties
 * ----------------
 * - `user`: Firebase user information
 * - `isAuthenticated`: User authentication status
 * - `tokenExpirationTime`: Token expiration time
 * - `openApiToken`: OpenAPI token tied to session
 */

export const useAuthStore = defineStore('sessionManagement', {
  state: () => ({
    user: null as User | null,
    isAuthenticated: false,
    tokenExpirationTime: 0,
    openApiToken: '' as string | undefined,
  }),

  actions: {
    async startSession(user: User): Promise<void> {
      const applicationStore = useApplicationStore();
      this.user = user;
      this.isAuthenticated = true;

      // Set OpenAPI token and update application store
      const idToken = await user.getIdToken();
      this.openApiToken = idToken;
      client.OpenAPI.TOKEN = idToken;
      await applicationStore.setActiveUser();
      applicationStore.applicationReady = true;

      // Set token expiration and start session timeout
      const tokenExpiration = await user.getIdTokenResult();
      this.tokenExpirationTime = new Date(tokenExpiration.expirationTime).getTime();
      this.startSessionTimeout();

      // Periodic check for token expiration
      clearInterval(expirationCheckInterval);
      expirationCheckInterval = setInterval(async () => {
        const currentTime = Date.now();
        const currentTokenExpiration = await user.getIdTokenResult();
        const currentExpirationTime = new Date(currentTokenExpiration.expirationTime).getTime();

        if (currentTime >= currentExpirationTime) {
          const currentRoute = router.currentRoute.value;
          const isPublicViewer = currentRoute.name === PageNames.ViewPublic;

          if (isPublicViewer) {
            await this.refreshTokenForPublicRoute();
          } else {
            await this.logoutSession();
          }
        }
      }, 60 * 1000); // Check every minute
    },

    startSessionTimeout(): void {
      const applicationStore = useApplicationStore();
      const sessionDuration = 55 * 60 * 1000; // 55 minutes
      const warningThreshold = 2 * 60 * 1000; // 2 minutes
      const logoutWarningTime = sessionDuration - warningThreshold;

      // Cancel any existing session timeouts
      clearTimeout(refreshTokenInterval);

      // Schedule a logout warning notification
      setTimeout(() => {
        applicationStore.publishErrorNotification({
          text: 'Please save your changes. Your session will expire in 2 minutes.',
          autoCloseMs: 20000,
        });
      }, logoutWarningTime);

      // Schedule the session logout
      refreshTokenInterval = setTimeout(async () => {
        await this.clearSession();
      }, sessionDuration);
    },

    async clearSession(): Promise<void> {
      // console.info('Clearing session...');
      // Reset stores and clear session properties
      resetAllStores();
      this.user = null;
      this.isAuthenticated = false;
      this.openApiToken = undefined;
      this.tokenExpirationTime = 0;
      client.OpenAPI.TOKEN = undefined;

      const applicationStore = useApplicationStore();
      applicationStore.applicationReady = false;

      // Clear intervals and timeouts
      clearInterval(expirationCheckInterval);
      clearTimeout(refreshTokenInterval);

      // Perform Firebase logout
      const firebaseAuth = getAuth();
      await signOut(firebaseAuth);

      // Verify logout completion
      firebaseAuth.onAuthStateChanged(async (currentUser) => {
        // console.warn('Auth state changed:', currentUser);
        if (!currentUser) {
          // Set application as ready and redirect to log in
          applicationStore.applicationReady = true;
          await router.push({ name: PageNames.Login, query: { message: 'Your session has timed out' } });
        } else {
          // console.warn('Failed to logout. Retrying...');
          // Retry sign-out if session persists
          await signOut(firebaseAuth);
        }
      });
    },

    async refreshTokenForPublicRoute(): Promise<void> {
      const currentUser = getAuth().currentUser;
      if (currentUser) {
        // console.info('Refreshing token for public view route');
        this.openApiToken = await currentUser.getIdToken(true);
        client.OpenAPI.TOKEN = this.openApiToken;

        const refreshedTokenResult = await currentUser.getIdTokenResult();
        this.tokenExpirationTime = new Date(refreshedTokenResult.expirationTime).getTime();
        this.startSessionTimeout();
      }
    },

    async logoutSession(message= 'Your session has timed out'): Promise<void> {
      // console.info('Logging out...');
      // Reset session and sign out
      resetAllStores();
      await signOut(getAuth());

      // Clear authentication state
      this.user = null;
      this.isAuthenticated = false;
      this.openApiToken = undefined;
      client.OpenAPI.TOKEN = undefined;

      // Update application state
      const applicationStore = useApplicationStore();
      applicationStore.applicationReady = true;

      // Clear intervals
      clearInterval(expirationCheckInterval);
      clearTimeout(refreshTokenInterval);

      // Redirect to login page
      await router.push({ name: PageNames.Login, query: { message } });
    }
  },
});

