<script lang="ts" setup>
import { useApplicationStore } from '@stores/application';

import ChangePasswordForm from '@components/account_forms/ChangePasswordForm.vue';
import Loading from '@components/Loading.vue';
import AccountLayout from '@layouts/AccountLayout.vue';

const applicationStore = useApplicationStore();
</script>

<template>
  <Loading v-if="!applicationStore.applicationReady" />

  <AccountLayout v-else>
    <div class="account-form">
      <section>
        <ChangePasswordForm />
      </section>
    </div>
    <div class="account-link-container">
      <router-link class="account-link"
                   to="/account">
        Back to account?
      </router-link>
    </div>
  </AccountLayout>
</template>
