<script setup lang="ts">
import Heading from '@components/Heading.vue';

const props = defineProps<{
  level: '1' | '2' | '3' | '4' | '5' | '6';
  heading: string;
}>();

const slots = defineSlots<{
  buttons?: () => void;
}>();

const level = props.level || '2';
</script>

<template>
  <header class="sub-heading d-flex flex-column flex-lg-row gap-10 mb-20">
    <Heading class="sub-heading__title flex-grow-1"
             :level="level">
      {{ heading }}
    </Heading>
    <div v-if="slots.buttons"
         class="sub-heading__actions d-flex flex-wrap flex-lg-row gap-10">
      <slot name="buttons" />
    </div>
  </header>
</template>
