import { ref } from 'vue';

import { defineStore } from 'pinia';

import { ScheduleViewModel } from '@viewModels/scheduleViewModel';

export const useScheduleServiceStore = defineStore('useScheduleServiceStore', () => {
  const monthlySheduleSections = ref<ScheduleViewModel[]>([]);
  const monthlyHeaterSheduleSections = ref<ScheduleViewModel[]>([]);
  const advancedCameraScheduleEnabled = ref<boolean>(true);
  const advancedHeaterScheduleEnabled = ref<boolean>(true);

  async function GetCameraSchedules(): Promise<ScheduleViewModel[]> {
    // const response = await wpClient.SchedulesService.getScheduleSchedulesGet(uuidv4(), "camera");
    // const schedules = convertListToCameraSchedule(response);
    const schedules: ScheduleViewModel[] = [new ScheduleViewModel()];
    advancedCameraScheduleEnabled.value = schedules.length > 1;
    return schedules;
  }
  async function SetCameraSchedules() {
    // const schedules = convertListToScheduleDto("camera", monthlySheduleSections.value)
    // await wpClient.SchedulesService.postScheduleSchedulesPost(uuidv4(), {schedules: schedules});
  }

  async function GetHeaterSchedules(): Promise<ScheduleViewModel[]> {
    // const response = await wpClient.SchedulesService.getScheduleSchedulesGet(uuidv4(), "heater");
    // const schedules = convertListToCameraSchedule(response);

    const schedules: ScheduleViewModel[] = [new ScheduleViewModel()];
    advancedHeaterScheduleEnabled.value = schedules.length > 1;
    return schedules;
  }
  async function SetHeaterSchedules() {
    // const schedules = convertListToScheduleDto("heater", monthlyHeaterSheduleSections.value)
    // await wpClient.SchedulesService.postScheduleSchedulesPost(uuidv4(), {schedules: schedules});
  }

  return {
    GetCameraSchedules,
    SetCameraSchedules,
    GetHeaterSchedules,
    SetHeaterSchedules,
    advancedCameraScheduleEnabled,
    monthlySheduleSections,
    monthlyHeaterSheduleSections,
    advancedHeaterScheduleEnabled,
  };
});
