<script setup lang="ts">
import { computed, ref } from 'vue';

import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';
import { ButtonVariant } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';
import Pagination from '@components/Pagination.vue';

const props = defineProps<{
  total: number,
  page: number,
  limit: number
}>();

defineEmits<{
  (e: 'pageChanged', page: number): void;
  (e: 'limitChanged', limit: number): void;
}>();

const perPage = ref({ value: props.limit, text: `${props.limit} per page` });
const hasPreviousPage = computed(() => props.page > 1);
const hasNextPage = computed(() => (props.page * props.limit) <= props.total);
const perPageOptions = [
  { value: 12, text: '12 per page' },
  { value: 24, text: '24 per page' },
  { value: 36, text: '36 per page' },
  { value: 48, text: '48 per page' },
  { value: 60, text: '60 per page' },
];
</script>

<template>
  <Pagination>
    <template #pagination-dropdown>
      <v-select v-model="perPage"
                label="text"
                :options="perPageOptions"
                :clearable="false"
                :searchable="false"
                :reduce="(value: any) => value.value"
                @option:selected="(e: any) => $emit('limitChanged', e.value)" />
    </template>

    <template #pagination-buttons>
      <ButtonComponent v-if="hasPreviousPage"
                       :icon-style="IconStyle.Outline"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.ChevronLeftIcon"
                       :is-outline-btn="true"
                       :is-icon-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="$emit('pageChanged', page - 1)" />

      <ButtonComponent v-if="hasNextPage"
                       :icon-style="IconStyle.Outline"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.ChevronRightIcon"
                       :is-outline-btn="true"
                       :is-icon-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="$emit('pageChanged', page + 1)" />
    </template>

    <template #pagination-info>
      <p>
        {{ ((props.page - 1) * props.limit) + 1 }} to
        {{ Math.min(props.total, ((props.page - 1) * props.limit) + props.limit) }}
        of {{ props.total }} results
      </p>
    </template>
  </Pagination>
</template>
