<script setup lang="ts">
import { ref } from 'vue';

import dayjs from 'dayjs';
import 'dayjs/locale/en';
import { DatePicker } from 'v-calendar';

const calendar = ref();

const props = defineProps<{
  modelValue: { start: dayjs.Dayjs; end: dayjs.Dayjs };
}>();

const range = ref({
  start: props.modelValue.start.toDate(),
  end: props.modelValue.end.toDate(),
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: { start: dayjs.Dayjs; end: dayjs.Dayjs }): void;
}>();

function onUpdateDate(e: any): void {
  emit('update:modelValue', {
    start: dayjs(e.start).startOf('day'),
    end: dayjs(e.end).startOf('day').endOf('day'),
  });
}
</script>

<template>
  <div class="energy-calendar">
    <DatePicker ref="calendar"
                v-model.range="range"
                expanded
                mode="date"
                :is-range="true"
                @update:model-value="onUpdateDate" />
  </div>
</template>

<style lang="scss">
@use '@scss/variables' as *;

// Page Specific Date Picker Styles
.energy-calendar {
  .vc-container {
    font-family: Poppins, sans-serif;
    color: $neutral-800;

    --vc-rounded-full: 5px;
    --vc-day-content-hover-bg: var(--primary-color, $primary-color);
    --vc-border: #{$neutral-300};

    .vc-highlight-bg-solid {
      --vc-highlight-solid-bg: var(--primary-color, $primary-color);
    }

    .vc-highlight-bg-light {
      --vc-highlight-light-bg: var(--primary-color, $primary-color);

      opacity: 0.1;
    }

    .vc-highlight-content-light {
      --vc-highlight-light-content-color: #{$neutral-800};
    }

    .vc-attr {
      --vc-content-color: var(--primary-color, $primary-color);
      --vc-highlight-outline-bg: var(--primary-color, $primary-color);
      --vc-highlight-outline-border: var(--primary-color, $primary-color);
      --vc-highlight-outline-content-color: $neutral-50;
      --vc-highlight-solid-bg: var(--primary-color, $primary-color);
      --vc-highlight-solid-content-color: $neutral-50;
      --vc-dot-bg: var(--primary-color, $primary-color);
      --vc-bar-bg: var(--primary-color, $primary-color);
    }

    .vc-weeks {
      .vc-week {
        .vc-day {
          .vc-day-content {
            &:hover {
              color: $neutral-50;
            }

            &.vc-highlight-content-solid {
              color: $neutral-50;
            }
          }
        }
      }
    }
  }

  .vc-nav-container {
    .vc-nav-item {
      --vc-nav-item-active-bg: var(--primary-color, $primary-color);
      --vc-nav-item-current-color: var(--secondary-color, $primary-color);
      --vc-nav-hover-bg: var(--primary-color, $primary-color);

      &:hover {
        color: $neutral-50;
      }
    }
  }
}
</style>
