<script setup lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';

import router from '@/router';
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { BreadcrumbPaths, ButtonType, ButtonVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import ButtonActions from '@layouts/ButtonActions.vue';
import Heading from '@components/Heading.vue';

interface ClientUpdateForm {
  name: string;
  primaryColour: string;
  secondaryColour: string;
  logo: string;
}
const schema = yup.object({
  name: yup.string().required(),
  primaryColour: yup.string().nullable(),
  secondaryColour: yup.string().nullable(),
  logo: yup.string().nullable(),
});

const { handleSubmit } = useForm<ClientUpdateForm>({
  validationSchema: schema,
});

const { value: nameValue, errorMessage: nameError } = useField<string>('name', 'name');
const { value: primaryColourValue, errorMessage: primaryColourError } = useField<string>(
  'primaryColour',
  'primaryColour'
);
const { value: secondaryColourValue, errorMessage: secondaryColourError } = useField<string>(
  'secondaryColour',
  'secondaryColour'
);
const { value: logoValue, errorMessage: logoError } = useField<string>('logo', 'logo');

const route = useRoute();
const applicationStore = useApplicationStore();
const isSubmitting = ref<boolean>(false);
const clientId = route.params['id'] as string;
const currentClient = await client.getClientById({ clientId });

nameValue.value = currentClient.name;
primaryColourValue.value = currentClient.settings?.primaryColour ?? applicationStore.defaultTheme.primaryColour ?? '';
secondaryColourValue.value = currentClient.settings?.secondaryColour ?? applicationStore.defaultTheme.secondaryColour ?? '';
logoValue.value = currentClient.settings?.logoBase64 ?? applicationStore.defaultTheme.logoBase64 ?? '';

const onSubmit = handleSubmit(async (values) => {
  isSubmitting.value = true;
  const requestBody: client.UpdateClientRequest = {
    name: values.name,
    settings: {
      logoBase64: values.logo,
      primaryColour: values.primaryColour,
      secondaryColour: values.secondaryColour,
    },
  };
  try {
    await client.updateClientById({ clientId, requestBody });
    applicationStore.publishSuccessNotification({
      text: 'Successfully updated client.',
      autoCloseMs: 3000,
    });
  } catch (error) {
    if (error instanceof client.ApiError) {
      // @ts-ignore
      applicationStore.publishErrorNotification({ text: error.body.error.message });
    }
    applicationStore.publishErrorNotification({ text: 'UNKNOWN ERROR' });
    return;
  } finally {
    isSubmitting.value = false;
  }
});

async function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (): void => {
      resolve((reader.result as string | null) || '');
    };
    reader.onerror = (error): void => {
      reject(error);
    };
  });
}

async function handleFileChange(event: Event): Promise<void> {
  const inputElement = event.target as HTMLInputElement;
  if (!inputElement.files) return;

  const file = inputElement.files[0];
  if (file) {
    logoValue.value = await fileToBase64(file);
  }
}

// Handle cancel button
const cancelBtn = (): void => {
  const routerHistory = router.options.history;
  const backUrl = routerHistory.state['back'];

  // If previous route is login, navigate to /clients
  if (typeof backUrl === 'string' && backUrl.startsWith('/login?continueUrl=')) {
    router.push(BreadcrumbPaths.Clients as string);
  } else if (routerHistory.state['back']) {
    // If there's a valid previous route, go back
    router.go(-1);
  } else {
    router.push(BreadcrumbPaths.Views as string);
  }
};
</script>

<template>
  <form @submit="onSubmit">
    <div class="field-group">
      <div class="field-group-info">
        <Heading level="3">
          Client Information
        </Heading>
        <p>Update a client and customise their experience with their logo and a theme.</p>
      </div>
      <div class="fields">
        <div class="field">
          <label for="client-name">Name</label>
          <input id="client-name"
                 v-model="nameValue"
                 type="text">
          <p class="message message-error">
            {{ nameError }}
          </p>
        </div>

        <div class="row-third">
          <div class="field">
            <label for="view-name">Logo</label>
            <input type="file"
                   @change="handleFileChange">
            <p class="message message-error">
              {{ logoError }}
            </p>
          </div>
          <div class="field">
            <label for="model">Primary Colour</label>
            <input id="organisation-primaryColour"
                   v-model="primaryColourValue"
                   type="color">
            <p class="message message-error">
              {{ primaryColourError }}
            </p>
          </div>
          <div class="field">
            <label for="model">Secondary Colour</label>
            <input id="organisation-secondaryColour"
                   v-model="secondaryColourValue"
                   type="color">
            <p class="message message-error">
              {{ secondaryColourError }}
            </p>
          </div>
        </div>
        <div class="client-theme">
          <div class="client-theme__sidebar"
               :style="{ 'background-color': primaryColourValue }">
            <img v-if="logoValue"
                 class="client-theme__sidebar--logo"
                 :src="logoValue"
                 alt="Current logo"
                 aria-hidden="true">
          </div>
          <div class="client-theme__content"
               :style="{ 'background-color': secondaryColourValue }" />
        </div>
      </div>
    </div>

    <ButtonActions>
      <ButtonComponent :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       :is-outline-btn="true"
                       @click="cancelBtn">
        Cancel
      </ButtonComponent>
      <ButtonComponent :is-block-btn="true"
                       :type="ButtonType.Submit"
                       :variant="ButtonVariant.Dark"
                       :loading="isSubmitting">
        Save
      </ButtonComponent>
    </ButtonActions>
  </form>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.client-theme {
  display: flex;
  overflow: hidden;
  border: 1px solid $neutral-300;
  border-radius: 5px;

  &__sidebar {
    padding: 10px 10px 60px;
    background-color: var(--primary-color, $primary-color);

    &--logo {
      height: 80px;
    }
  }

  &__content {
    width: 100%;
    height: 100px;
    background-color: var(--secondary-color, $secondary-color);
  }
}
</style>
