import { getApp, initializeApp } from 'firebase/app';

const isProd = ['hub.gabrielcam.com', 'live.gabrielcam.com', 'admin.gabrielcam.com'].includes(window.location.hostname);

initializeApp({
  apiKey: isProd ? 'AIzaSyByryC8B73wVADlDFvoCjpJE0GEhRJVC8E' : 'AIzaSyBidlPTHT-Iy8_rPgFl_Bw700GIQsDEVeg',
  authDomain: isProd ? 'gc-prod-5574604.firebaseapp.com' : 'gc-uat-2191033.firebaseapp.com',
});

const firebaseApp = getApp();

export { firebaseApp };
