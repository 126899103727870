import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

import { useApplicationStore } from '@stores/application';
import { PageNames } from '@viewModels/enums';
import checkPublicView from '@middleware/check-public-view';

/**
 * Authentication required middleware function.
 *
 * This function checks if a route needs to be authenticated by inspecting the route's meta field.
 * If the route is not public and the user is not authenticated, it redirects to the login page.
 *
 * @param to RouteLocationNormalized The route to check for authentication.
 * @returns Promise<RouteLocationRaw | void> The redirect route if authentication is required, otherwise void.
 */
export default async function (to: RouteLocationNormalized): Promise<RouteLocationRaw | void> {

  // If Public Viewer and isPublic is true, set the public meta property to true via checkPublicView middleware to allow access
  if (to.name === PageNames.ViewPublic) {
    await checkPublicView(to);
  }

  if (to.meta['public']) return;

  const applicationStore = useApplicationStore();
  const user = applicationStore.activeUser;

  const query: { [key: string]: any } = {};
  if (to.query['apiKey'] && to.query['oobCode'] && to.query['mode']) {
    query['apiKey'] = to.query['apiKey'];
    query['oobCode'] = to.query['oobCode'];
    query['mode'] = to.query['mode'];
  }

  query['continueUrl'] = to.path !== '/' ? to.path : '/views';

  if (!user) return { name: PageNames.Login, query };
}
