<script setup lang="ts">
import { onMounted, ref } from 'vue';
import * as yup from 'yup';
import timezones, { TimeZone } from 'timezones-list';
import * as client from '@gabrielcam/api-client';
import { UpdateUserRequest } from '@gabrielcam/api-client';
import { useRoute } from 'vue-router';

import ContainerCard from '@components/cards/ContainerCard.vue';
import { ErrorMessage, useForm } from 'vee-validate';
import { ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';
import ButtonActions from '@layouts/ButtonActions.vue';
import Loading from '@components/Loading.vue';
import { useApplicationStore } from '@stores/application';
import Heading from '@components/Heading.vue';

const route = useRoute();
const applicationStore = useApplicationStore();
let isLoading = false;
const userEmail = ref<string>();
const userStatus = ref<string>();

const props = defineProps<{
  userId: string
}>();

const emit = defineEmits<{
  (e: 'onUpdated'): void;
}>();

const { handleSubmit, resetForm, defineField, meta, isSubmitting } = useForm({
  validationSchema: yup.object({
    forename: yup.string().trim().required(),
    surname: yup.string().trim().required(),
    timezone: yup.string().trim().required(),
  }),
});

const [forenameValue] = defineField<string>('forename');
const [surnameValue] = defineField<string>('surname');
const [timezoneValue] = defineField<string>('timezone');

const onSubmit = handleSubmit(async (values): Promise<void> => {
  try {
    const request: client.UpdateUserByIdData = {
      userId: props.userId,
      requestBody: {
        displayName: values['forename'].trim(),
        forename: values['forename'].trim(),
        surname: values['surname'].trim(),
        timezone: values['timezone'],
      },
    };
    const responseUser = await client.updateUserById(request);
    resetUserForm(responseUser);
    emit('onUpdated');
    applicationStore.publishSuccessNotification({
      text: 'Successfully updated.',
      autoCloseMs: 3000,
    });
  } catch {
    applicationStore.publishErrorNotification({ text: 'Error while updating.' });
    return;
  }
});

const resetUserForm = (user: UpdateUserRequest): void => {
  resetForm({
    values: {
      forename: user.forename,
      surname: user.surname,
      timezone: timezones.find(tz => tz.tzCode === user.timezone)?.tzCode,
    },
  });
};

/**
 * Fetches the correct user details based on the current route
 *
 * If the user is viewing their own account: Sets `isCurrentUser` to true and uses `getUserSelf`
 * If the user is viewing another user's account: Sets `isCurrentUser` to false and uses `getUserById`
 */
onMounted(async () => {
  isLoading = true;

  // True if viewing own profile page or `id` matches active user.
  const isCurrentUser = route.name === PageNames.Account || route.params?.['id'] === applicationStore.activeUser?.id;

  let user;

  // Fetch data based on the route
  if (isCurrentUser) {
    user = await client.getUserSelf();
  } else if (typeof route.params?.['id'] === 'string') {
    // Ensure `id` is a single string before passing it to `getUserById`
    user = await client.getUserById({ userId: route.params?.['id'] });
  } else {
    console.warn('Invalid user ID provided');
    return;
  }

  resetUserForm(user);
  userEmail.value = user.email;
  userStatus.value = user.status;
  isLoading = false;
});
</script>

<template>
  <ContainerCard>
    <Loading v-if="isLoading" />
    <section v-else>
      <form @submit="onSubmit">
        <div class="field-group">
          <div class="field-group-info">
            <Heading level="3">
              User Information
            </Heading>
            <p>Edit user's information.</p>
          </div>
          <div class="fields">
            <div class="row-half">
              <div class="field">
                <label for="forename">Forename</label>
                <input id="forename"
                       v-model="forenameValue"
                       type="text">
                <ErrorMessage name="forename" class="message-error message" as="p" />
              </div>

              <div class="field">
                <label for="surname">Surname</label>
                <input id="surname"
                       v-model="surnameValue"
                       type="text">
                <ErrorMessage name="surname" class="message-error message" as="p" />
              </div>
            </div>

            <div class="row-half">
              <div class="field">
                <label for="email">Email</label>
                <input id="email"
                       v-model="userEmail"
                       disabled
                       readonly
                       type="text">
              </div>

              <div class="field">
                <label for="status">Status</label>
                <input id="status"
                       v-model="userStatus"
                       disabled
                       type="text">
              </div>
            </div>

            <div class="row-half">
              <div class="field">
                <label for="timezone">Timezone</label>
                <v-select id="timezone"
                          v-model="timezoneValue"
                          :reduce="(timezone: TimeZone) => timezone.tzCode"
                          :options="timezones" />
                <ErrorMessage name="timezone" class="message-error message" as="p" />
              </div>
            </div>
          </div>
        </div>

        <ButtonActions>
          <ButtonComponent :is-block-btn="true"
                           :type="ButtonType.Submit"
                           :variant="ButtonVariant.Dark"
                           :disabled="isSubmitting || !meta.dirty || !meta.valid">
            Update
          </ButtonComponent>
        </ButtonActions>
      </form>
    </section>
  </ContainerCard>
</template>
