<template>
  <main class="public-viewer--container">
    <slot name="router-view" />
  </main>
</template>


<style lang="scss" scoped>
@use '@scss/variables' as *;

.public-viewer {
  &--container {
    padding: 30px clamp($gap-mobile, 3vw, $gap-desktop) 0;
    background-image: linear-gradient(to bottom, var(--secondary-color, $secondary-color, 0.5) 40vh, transparent 0%);
  }
}
</style>
