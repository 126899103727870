<script setup lang="ts">
import { onMounted } from 'vue';
import AccountLayout from '@layouts/AccountLayout.vue';
import { useAuthStore } from '@stores/useAuthStore';


/**
 * Reset all the stores, signs the user out, clears the OpenAPI token,
 * and redirects the user to the login page with a success message.
 */
onMounted(async () => {
  const authStore = useAuthStore();
  await authStore.logoutSession('Successfully logged out');
});
</script>

<template>
  <AccountLayout>
    <p>You are now logged out...</p>
  </AccountLayout>
</template>
