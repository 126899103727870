<script setup lang="ts">
import { computed, PropType, ref, watch } from 'vue';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import ButtonComponent from '@components/ButtonComponent.vue';
import { useMobileDetection } from '@utils/isMobile';
import ViewerDatePicker from '@components/viewer/ViewerDatePicker.vue';
import { DatePickerTheme } from '@viewModels/enums';
import { ViewerImage } from '@/types';

const { isMobile } = useMobileDetection();

const props = defineProps({
  dates: {
    type: Object as PropType<string[]>,
    required: true,
  },
  selectedDate: {
    type: String,
    required: true,
  },
  images: {
    type: Object as PropType<Map<string, ViewerImage[]>>,
    required: true,
  },
  selectedImage: {
    type: Object as PropType<ViewerImage>,
    required: true,
  },
});

const emit = defineEmits<{
  (e: 'imageSelected', id: ViewerImage): void;
  (e: 'selectedDateChanged', id: string): void;
}>();

const today = new Date().toISOString().split('T')[0]!;
const selectedDateString = ref(props.dates.at(-1) || today);
const imageElements = ref<Map<string, HTMLElement>>(new Map<string, HTMLElement>());

watch(
  () => props.selectedDate,
  () => {
    catalogueDateInput.value = props.selectedDate;
    selectedDateString.value = props.selectedDate;
  },
);
watch(
  () => props.selectedImage,
  () => {
    const selectedImageElement = imageElements.value.get(props.selectedImage.id);
    if (!selectedImageElement) {
      console.warn('Image element not defined');
      return;
    }
    selectedImageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  },
);

function addImageElement(id: string, element: HTMLElement | undefined): void {
  if (element) imageElements.value.set(id, element);
}

const catalogueDateInput = computed({
  get() {
    return selectedDateString.value;
  },
  set(newValue) {
    selectedDateString.value = newValue;
    emit('selectedDateChanged', selectedDateString.value);
  },
});

function scrollCatalogue(direction: 'up' | 'down'): void {
  const catalogue = document.querySelector<HTMLDivElement>('div.viewer__catalogue--track');
  if (!catalogue) return;

  const scrollAmount = catalogue.offsetHeight * 0.9;

  if (direction === 'up') {
    catalogue.scrollTop -= scrollAmount;
  } else if (direction === 'down') {
    catalogue.scrollTop += scrollAmount;
  }
}

function selectImage(image: ViewerImage): void {
  if (!image) return;
  emit('imageSelected', image);
}
</script>

<template>
  <div class="viewer__catalogue">
    <ViewerDatePicker v-model="selectedDateString"
                      :variant="isMobile ? DatePickerTheme.Light : DatePickerTheme.Dark"
                      :available-dates="dates"
                      :selected-date="selectedDateString"
                      @update:model-value="
                        (e: string) => {
                          catalogueDateInput = e;
                        }" />

    <div class="viewer__catalogue--track">
      <ButtonComponent class="track__button track__button--scroll-up"
                       :is-icon-btn="true"
                       :icon-name="IconName.ChevronUpIcon"
                       :icon-style="IconStyle.Outline"
                       aria-label="Scroll Up"
                       @click="scrollCatalogue('up')" />

      <section class="track__images">
        <div v-for="image in images.get(catalogueDateInput)"
             :id="'track__images--item-' + image.id"
             :key="image.id"
             :ref="(e: any) => addImageElement(image.id, e)"
             class="track__images--item"
             :class="{ 'active-item': selectedImage?.id == image.id }"
             @click="selectImage(image)">
          <img :src="image.sourceURL + '?w=300'"
               aria-hidden="true"
               :alt="image.originalFileName">

          <p class="track__date">
            {{ image.formattedTime }}
          </p>
        </div>
      </section>

      <ButtonComponent class="track__button track__button--scroll-down"
                       :is-icon-btn="true"
                       :icon-name="IconName.ChevronDownIcon"
                       :icon-style="IconStyle.Outline"
                       aria-label="Scroll Down"
                       @click="scrollCatalogue('down')" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.viewer__catalogue {
  display: flex;
  flex-direction: column;
  height: calc(100% - 180px); // 80px is the height of the mobile menu
  margin-bottom: 20px;

  @media screen and (min-width: $breakpoint-lg) {
    height: 100%;
    margin-bottom: unset;
  }

  &--track {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    overflow-y: auto;
    scroll-behavior: smooth;

    @media screen and (min-width: $breakpoint-lg) {
      gap: 10px;
      margin-bottom: 0;
    }

    & .track__images {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;

      @media screen and (min-width: $breakpoint-lg) {
        position: relative;
        top: -50px;
        gap: 10px;
        width: 100%;
        padding: 0 10px;
      }

      &--item {
        position: relative;
        display: inline-block;
        width: 100%;
        max-width: min(320px, 46%);
        margin: 0;
        overflow: hidden;
        line-height: 0;
        cursor: pointer;

        @media screen and (min-width: $breakpoint-lg) {
          max-width: 100%;
          margin: 0 4px 4px 0;
        }

        img {
          width: 100%;
          transition: all 0.3s ease;
        }

        @media screen and (min-width: $breakpoint-lg) {
          &:last-of-type {
            margin-bottom: -36px;
          }
        }

        &.active-item,
        &:active,
        &:hover {
          img {
            transform: scale(1.1);
          }

          &::before {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            content: '';
            border: 4px solid var(--secondary-color, $secondary-color);
          }
        }
      }
    }

    & .track__date {
      position: absolute;
      right: 5px;
      bottom: 5px;
      padding: 7px;
      margin: 0;
      font-size: 0.8rem;
      line-height: 1;
      color: $neutral-50;
      background: $black-opacity-75;
      border-radius: 5px;
      opacity: 0.9;
    }

    &::-webkit-scrollbar {
      width: 15px;
    }

    &::-webkit-scrollbar-track {
      background: $black-opacity-50;
      border-right: 1px solid $white-opacity-25;
      border-left: 1px solid $white-opacity-25;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--secondary-color, $secondary-color);
      border: 2px solid $white-opacity-50;
      border-radius: 10px;
      outline: 2px solid $white-opacity-50;
    }

    & .track__button {
      position: absolute;
      top: 0;
      left: calc(50% - 18px);
      z-index: 2;
      display: none;
      width: 36px;
      height: 36px;
      line-height: 1;
      color: $neutral-50;
      background: $black-opacity-75;
      border: none;
      border-radius: 0 0 5px 5px;
      outline: 1px solid $white-opacity-25;
      outline-offset: 0;

      @media screen and (min-width: $breakpoint-lg) {
        position: sticky;
        display: block;
      }

      &:hover,
      &:focus {
        background: var(--secondary-color, $secondary-color);
        outline: 1px solid $white-opacity-25;
        outline-offset: 0;
      }

      &--scroll-down {
        position: fixed;
        top: auto;
        bottom: 0;
        display: none;
        margin-left: -10px;
        border-radius: 5px 5px 0 0;

        @media screen and (min-width: $breakpoint-lg) {
          position: sticky;
          display: block;
          margin-left: 0;
        }
      }
    }
  }
}

// Mobile styles
.viewer__sidebar--is-open {
  top: 80px;

  .viewer__catalogue {
    @media screen and (width <= 1059px) and (orientation: landscape) {
      flex-direction: row;

      .vc-expanded {
        min-width: auto;
      }

      .viewer__catalogue--track {
        .viewer-button {
          &.viewer-button-down {
            margin-left: 110px;
          }
        }
      }
    }

    .viewer__catalogue--track {
      .viewer-button {
        &.viewer-button-down {
          display: block;
        }
      }
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    top: 0;
  }
}
</style>


<!--Date Picker Styles for View Viewer Page & Public Viewer Page-->
<style lang="scss">
@use '@scss/variables' as *;

.viewer__catalogue {
  .vc-container {
    font-family: inherit;

    .vc-pane {
      min-width: auto;
    }

    .vc-pane-container {
      .vc-header {
        height: 60px;
        padding: 0 5px;
        margin: 0;
        font-size: 1.15em;

        .vc-arrow {
          width: 28px;
          height: 28px;
          color: $neutral-800;
          cursor: pointer;
          background: none;
          border-radius: 5px;

          @media screen and (min-width: $breakpoint-lg) {
            color: inherit;
          }

          &:hover,
          &:active,
          &:focus,
          &:focus-visible {
            outline: 1px solid $white-opacity-25;
            outline-offset: 0;
          }
        }
      }
    }

    .vc-weeks {
      min-width: 228px;
      padding: 0;

      .vc-weekdays {
        border-bottom: 1px solid $white-opacity-25;

        .vc-weekday {
          color: $neutral-800;

          @media screen and (min-width: $breakpoint-lg) {
            color: inherit;
          }
        }
      }

      .vc-week {
        .vc-day {
          .vc-day-content {
            color: $neutral-800;
            border-radius: 5px;

            @media screen and (min-width: $breakpoint-lg) {
              color: inherit;
            }

            &.vc-highlight-content-solid {
              color: $neutral-50;

              @media screen and (min-width: $breakpoint-lg) {
                color: inherit;
              }
            }

            &.vc-disabled {
              opacity: 0.3;
            }
          }

          &.is-not-in-month {
            .vc-day-content {
              opacity: 0;
            }
          }
        }
      }

      .vc-highlight {
        background: $red-800;
        background: var(--secondary-color, $secondary-color);
        border-radius: 5px;
      }
    }
  }

  .vc-highlight {
    background-color: var(--secondary-color, $secondary-color);
    border-color: var(--secondary-color, $secondary-color);
  }

  .vc-title {
    color: $neutral-800 !important;
    pointer-events: none !important;
    background: none;
    border-radius: 0 !important;

    @media screen and (min-width: $breakpoint-lg) {
      color: inherit !important;
    }
  }

  .vc-disabled {
    color: pink;
    pointer-events: none;
  }

  .vc-focus {
    &:focus-within {
      box-shadow: none !important;
    }
  }
}
</style>
