import { Ref, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

/**
 * A custom hook that provides the current route information.
 *
 * @returns An object containing the current route name, path, and meta title.
 */
export function useCurrentRoute(): {
  currentRouteName: Ref<string | symbol | undefined>;
  currentRoutePath: Ref<string>;
  currentRouteMetaTitle: Ref<string | "">
} {
  const route = useRoute();
  const { path, name, meta } = route;

  const currentRoutePath = ref(path);
  const currentRouteName = ref(name);
  const currentRouteMetaTitle = ref(meta['title'] as string | '');

  watch(route, () => {
    currentRoutePath.value = route.path;
    currentRouteName.value = route.name;
    currentRouteMetaTitle.value = route.meta['title'] as string | '';
  });

  return {
    currentRoutePath,
    currentRouteName,
    currentRouteMetaTitle,
  };
}
