<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import dayjs from 'dayjs';
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import * as client from '@gabrielcam/api-client';
import { View } from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { BreadcrumbPaths, ButtonType, ButtonVariant, PageNames } from '@viewModels/enums';
import { Resolution } from '@viewModels/resolutionViewModel';

import ButtonComponent from '@components/ButtonComponent.vue';
import Heading from '@components/Heading.vue';
import Loading from '@components/Loading.vue';
import ButtonActions from '@layouts/ButtonActions.vue';

import DatePicker from './DatePicker.vue';
import ResolutionDropDown from './ResolutionDropDown.vue';

const props = defineProps<{
  viewId: string | undefined;
}>();

interface VideoCreationForm {
  view: string;
  resolution: Resolution;
  frameRate: number;
  duration: number;
  startDate: Date;
  endDate: Date;
  startTime: string;
  endEnd: string;
}

const schema = yup.object({
  view: yup.string().required(),
  startDate: yup.date().typeError('Start Time is required').required(),
  endDate: yup.date().typeError('End Time is required').required(),
  frameRate: yup.number().min(5).max(60).typeError('Frame rate must be a number').required(),
  duration: yup.number().min(5).max(300).typeError('Duration must be a number').required(),
  startTime: yup
    .string()
    .matches(/^(?:[01]\d|2[0-3]):[0-5]\d$/, 'Invalid Format')
    .required(),
  endTime: yup
    .string()
    .matches(/^(?:[01]\d|2[0-3]):[0-5]\d$/, 'Invalid Format')
    .required(),
});

const { handleSubmit, isSubmitting } = useForm<VideoCreationForm>({
  validationSchema: schema,
});

const { value: viewValue, errorMessage: viewError } = useField<string | undefined>('view');
const { value: startDateValue, errorMessage: startDateError } = useField<Date>('startDate');
const { value: endDateValue, errorMessage: endDateError } = useField<Date>('endDate');
const { value: resolutionValue, errorMessage: resolutionError } = useField<Resolution>('resolution');
const { value: frameRateValue, errorMessage: frameRateError } = useField<number>('frameRate');
const { value: durationValue, errorMessage: durationError } = useField<number>('duration');
const { value: startTimeValue, errorMessage: startTimeError } = useField<string>('startTime');
const { value: endTimeValue, errorMessage: endTimeError } = useField<string>('endTime');

startTimeValue.value = '09:00';
endTimeValue.value = '17:00';
frameRateValue.value = 25;
durationValue.value = 30;

const router = useRouter();
const applicationStore = useApplicationStore();
const currentSectionNumber = ref<number>(0);
const firstSectionNumber = ref<number>(0);
const lastSectionNumber = ref<number>(2);
const isLoading = ref<boolean>(false);
const availableDates = ref<string[]>([]);
const allImages = ref<string[]>([]);
const filteredImages = ref<string[]>([]);

let today = dayjs().endOf('day');
let oneWeekAgo = dayjs().subtract(1, 'week').startOf('day');
startDateValue.value = oneWeekAgo.toDate();
endDateValue.value = today.toDate();

let filteredViews: View[] = [];
if (props.viewId) {
  const view = await client.getViewById({ viewId: props.viewId });
  filteredViews = [view];
} else {
  const views = await client.listViews({ organisation: applicationStore.activeOrganisation!.id });
  filteredViews = [...views.data];
}

if (filteredViews.length === 1) {
  viewValue.value = filteredViews.at(0)!.id;
  await viewSelected(filteredViews.at(0)!);
}

watch(currentSectionNumber, async () => {
  if (currentSectionNumber.value === 0) return;
  if (viewValue.value === undefined) return;
  allImages.value = await getImages(
    viewValue.value,
    startDateValue.value.toISOString(),
    endDateValue.value.toISOString()
  );
  filteredImages.value = filterImages(allImages.value);
});

const onSubmit = handleSubmit(async (values) => {
  if (applicationStore.activeOrganisation == undefined) return;
  const images = await getImages(values.view, startDateValue.value.toISOString(), endDateValue.value.toISOString());
  filteredImages.value = filterImages(images);
  const view = filteredViews.find((x) => x.id == values.view);
  const requestBody: client.CreateSequenceRequest = {
    view: values.view,
    name: `${view?.name} ${values.startDate.toDateString()} - ${values.endDate.toDateString()}`,
    organisation: applicationStore.activeOrganisation.id,
    options: {
      bitrate: values.resolution.bitrate, //8500
      blend: 'average',
      crop: true,
      framerate: values.frameRate,
      height: values.resolution.height,
      width: values.resolution.width,
    },
    images: filteredImages.value,
  };
  if (requestBody.images.length == 0) {
    applicationStore.publishErrorNotification({ text: 'No images within this period.' });
    return;
  }

  try {
    await client.createSequence({ requestBody });
  } catch (error) {
    console.error(error);
    applicationStore.publishErrorNotification({ text: 'Error creating video' });
    return;
  }

  applicationStore.publishSuccessNotification({
    text: 'Successfully queued the video creation.',
    autoCloseMs: 3000,
  });

  router.push({ name: PageNames.Videos, params: { viewId: props.viewId } });
});

async function getImages(viewId: string, startDate: string, endDate: string): Promise<string[]> {
  try {
    const response = await client.listViewByIdImages({
      viewId,
      startDate,
      endDate,
      status: client.ImageStatus.COMPLETE,
    });
    return response.data.map((image) => image.originalFileName!);
  } catch (error) {
    console.error(error);
    applicationStore.publishErrorNotification({ text: 'Error fetching images' });
    isSubmitting.value = false;
    return [];
  }
}

function filterImages(images: string[]): string[] {
  const startTime = startTimeValue.value.split(':');
  const startHour = Number(startTime[0]);
  const startMinute = Number(startTime[1]);
  const endTime = endTimeValue.value.split(':');
  const endHour = Number(endTime[0]);
  const endMinute = Number(endTime[1]);

  let selectedImages = images.filter((image) => {
    const splitFileName = image.split('_');
    if (splitFileName.length != 2) return false;

    const imageTime = splitFileName[1]!.split('-');
    if (imageTime.length != 3) return false;

    const imageHour = Number(imageTime[0]);
    const imageMinute = Number(imageTime[1]);

    if (imageHour >= startHour && imageHour <= endHour) {
      if (imageHour === startHour) {
        return imageMinute >= startMinute;
      }
      if (imageHour === endHour) {
        return imageMinute <= endMinute;
      }
      return true;
    }
    return false;
  });

  if (durationValue.value !== 0) {
    const maxNumberImages = frameRateValue.value * durationValue.value;
    const currentNumberOfImages = selectedImages.length;
    const delta = Math.floor(currentNumberOfImages / maxNumberImages);

    if (currentNumberOfImages > maxNumberImages) {
      selectedImages = selectedImages.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
      const imagesLimited: string[] = [];
      for (let i = 0; i < selectedImages.length; i = i + delta) {
        imagesLimited.push(selectedImages[i]!);
      }
      selectedImages = imagesLimited;
    }
  }

  return selectedImages;
}

async function viewSelected(view: View): Promise<void> {
  isLoading.value = true;
  currentSectionNumber.value = 0;
  try {
    availableDates.value = (await client.listViewByIdCapturedDates({ viewId: view.id })).data;
    startDateValue.value = today.toDate();
  } catch (error) {
    console.error('Error fetching dates:', error);
    applicationStore.publishErrorNotification({ text: 'Error fetching dates' });
  } finally {
    isLoading.value = false;
  }
}

// Handle navigation
const cancelBtn = (): void => {
  const routerHistory = router.options.history;
  const backUrl = routerHistory.state['back'];

  // If previous route is login, navigate to /videos
  if (typeof backUrl === 'string' && backUrl.startsWith('/login?continueUrl=')) {
    router.push(BreadcrumbPaths.Videos as string);
  } else if (routerHistory.state['back']) {
    // If there's a valid previous route, go back
    router.go(-1);
  } else {
    router.push(BreadcrumbPaths.Views as string);
  }
};
</script>

<template>
  <form @submit="onSubmit">
    <div class="field-group">
      <div class="field-group-info">
        <Heading level="3">
          Video Information
        </Heading>
        <p>
          Create a new video from a view. Firstly select a date range, then the time of day, then your resolution and
          duration.
        </p>
      </div>

      <div class="fields">
        <div>
          <div class="field">
            <label for="model">View</label>
            <v-select v-model="viewValue"
                      :options="filteredViews"
                      :reduce="(view: client.View) => view.id"
                      label="name"
                      @search="
                        (search: string) => {
                          filteredViews = filteredViews.filter((x) => {
                            if (!!search.length) return true;
                            return x.name.toLowerCase().includes(search.toLowerCase());
                          });
                        }
                      "
                      @option:selected="viewSelected" />
            <p class="message message-error">
              {{ viewError }}
            </p>
          </div>
          <!-- <div class="field">
            <label for="model">Number Images Selected</label>
            <p>{{ filteredImages.length }} / {{ allImages.length }}</p>
          </div> -->
        </div>

        <Loading v-if="!isLoading" />

        <div v-else>
          <div v-if="currentSectionNumber === 0"
               class="row-half panel">
            <div class="field">
              <label for="model">Start Date</label>
              <DatePicker v-model="startDateValue"
                          :avaliable-dates="availableDates"
                          @update:model-value="
                            (e: Date) => {
                              startDateValue = e;
                            }
                          " />
              <p class="message message-error">
                {{ startDateError }}
              </p>
            </div>
            <div class="field">
              <label for="model">End Date</label>
              <DatePicker v-model="endDateValue"
                          :avaliable-dates="availableDates"
                          @update:model-value="
                            (e: Date) => {
                              endDateValue = e;
                            }
                          " />
              <p class="message message-error">
                {{ endDateError }}
              </p>
            </div>
          </div>

          <div v-if="currentSectionNumber === 1"
               class="row-half">
            <div class="field">
              <label class="label"
                     for="">Daily start time:</label>
              <input v-model="startTimeValue"
                     class="input"
                     name="dailyStart"
                     type="time">
              <p class="message message-error">
                {{ startTimeError }}
              </p>
            </div>
            <div class="field">
              <label class="label"
                     for="">Daily end time:</label>
              <input v-model="endTimeValue"
                     class="input"
                     name="dailyStart"
                     type="time">
              <p class="message message-error">
                {{ endTimeError }}
              </p>
            </div>
          </div>

          <div v-if="currentSectionNumber === 2">
            <div class="row-half">
              <div class="field">
                <label class="label"
                       for="">Resolution</label>
                <ResolutionDropDown @on-selected="(value: Resolution) => (resolutionValue = value)" />
                <p class="message message-error">
                  {{ resolutionError }}
                </p>
              </div>
              <div class="field">
                <label class="label"
                       for="">Frame Rate</label>
                <input v-model="frameRateValue"
                       class="input"
                       name="dailyStart"
                       type="number">
                <p class="message message-error">
                  {{ frameRateError }}
                </p>
              </div>
            </div>
            <div class="row-half">
              <div class="field">
                <p>
                  <strong>Note:</strong>
                  Our algorithm optimises the number of photos included. The final duration of the video may not be
                  exact.
                </p>
              </div>
              <div class="field">
                <label class="label"
                       for="">Duration (seconds)</label>
                <input v-model="durationValue"
                       class="input"
                       type="number">
                <p class="message message-error">
                  {{ durationError }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ButtonActions>
      <ButtonComponent v-if="currentSectionNumber === firstSectionNumber"
                       :is-block-btn="true"
                       :is-outline-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="cancelBtn">
        Cancel
      </ButtonComponent>

      <ButtonComponent v-if="currentSectionNumber != firstSectionNumber"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="currentSectionNumber--">
        Previous
      </ButtonComponent>

      <ButtonComponent v-if="currentSectionNumber != lastSectionNumber"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="currentSectionNumber++">
        Next
      </ButtonComponent>

      <ButtonComponent v-if="currentSectionNumber === lastSectionNumber"
                       :type="ButtonType.Submit"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       :disabled="isSubmitting">
        Create
      </ButtonComponent>
    </ButtonActions>
  </form>
</template>
