<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import * as client from '@gabrielcam/api-client';

import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';

const route = useRoute();
const cameraId = route.params['id'] as string;
const currentCamera = ref<client.Camera>();
const isLoading = ref<boolean>(true);

// Get current camera data
onMounted(async () => {
  try {
    currentCamera.value = await client.getCameraById({ cameraId });
  } catch (error) {
    console.error('Error fetching camera data:', error);
  } finally {
    isLoading.value = false;
  }
});
</script>

<template>
  <ContainerCard>
    <Loading v-if="isLoading" />

    <div v-else>
      <p>{{ currentCamera?.serialNumber }} Network Page Content</p>
    </div>
  </ContainerCard>
</template>
