import { Ref, ref } from 'vue';

import { defineStore } from 'pinia';

// import * as client from '@gabrielcam/api-client';
import { Entitlements } from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { AdminMenuTitles, MainMenuTitles } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';

export interface NavItem {
  to?: string;
  icon?: IconName;
  iconStyle?: IconStyle;
  title?: string;
  viewPermission?: boolean;
  children?: NavItem[];
  isOpen?: boolean;
  isDropdown?: boolean;
  separator?: boolean;
}

export const useNavStore = defineStore('useNavStore', () => {
  const navItems: Ref<NavItem[]> = ref([]);

  const applicationStore = useApplicationStore();

  // Set View Permissions
  const readView = applicationStore.canUser(Entitlements.READ_VIEW, applicationStore.activeOrganisation!);
  // const readVideo = applicationStore.canUser(Entitlements.READ_SEQUENCE, applicationStore.activeOrganisation!);
  const readCamera = applicationStore.canUser(Entitlements.READ_CAMERA, applicationStore.activeOrganisation!);
  const readProject = applicationStore.canUser(Entitlements.READ_PROJECT, applicationStore.activeOrganisation!);
  const readClient = applicationStore.canUser(Entitlements.READ_CLIENT, applicationStore.activeOrganisation!);
  const readUser = applicationStore.canUser(Entitlements.READ_USER, applicationStore.activeOrganisation!);
  const readOrganisation = applicationStore.canUser(Entitlements.READ_ORGANISATION, applicationStore.activeOrganisation!);

  // Main Menu
  function setMainMenu(): void {
    navItems.value = [
      {
        to: '/views/',
        icon: IconName.HomeIcon,
        iconStyle: IconStyle.Solid,
        title: MainMenuTitles.AllViews,
      },
      {
        to: '/videos/',
        icon: IconName.VideoCameraIcon,
        iconStyle: IconStyle.Solid,
        title: MainMenuTitles.Videos,
      },
      {

        to: '/admin',
        icon: IconName.Cog6ToothIcon,
        iconStyle: IconStyle.Solid,
        title: MainMenuTitles.Admin,
        viewPermission: readCamera || readView || readProject || readClient,
        children: [
          { to: '/admin/cameras', title: MainMenuTitles.Cameras, viewPermission: readCamera },
          { to: '/admin/views', title: AdminMenuTitles.Views, viewPermission: readView },
          { to: '/admin/projects', title: AdminMenuTitles.Projects, viewPermission: readProject },
          { to: '/admin/clients', title: AdminMenuTitles.Clients, viewPermission: readClient },
          { to: '/admin/organisations', title: AdminMenuTitles.Organisations, viewPermission: readOrganisation },
          { to: '/admin/users', title: AdminMenuTitles.Users, viewPermission: readUser },
        ],
      },
    ] as NavItem[];
  }

  return {
    navItems,
    setMainMenu,
  };
});
