<script setup lang="ts">
import * as client from '@gabrielcam/api-client';
import { BadgeVariant, ButtonVariant, PageNames } from '@viewModels/enums';
import Heading from '@components/Heading.vue';
import BadgeComponent from '@components/BadgeComponent.vue';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import ButtonComponent from '@components/ButtonComponent.vue';
import { ref } from 'vue';
import ModalComponent from '@components/ModalComponent.vue';
import router from '@/router';
import { useApplicationStore } from '@stores/application';

const emit = defineEmits(['roles-updated']);
const props = defineProps<{
  user: client.User,
  isLoading: boolean
  projects: Array<client.Project>,
  views: Array<client.View>,
  roles: Array<client.Role>
}>()

const applicationStore = useApplicationStore();
const isRevoking = ref<boolean>(false);
const showConfirmRevokeLastRole = ref<boolean>(false);
const lastRoleRef = ref<{ roleId: string, resourceId: string }>();

const revokeApiRole = async (roleId: string, resourceId: string): Promise<void> => {
  isRevoking.value = true;
  try {
    await client.revokeUserByIdRoleByIdResourceById({
      organisation: applicationStore.activeOrganisation!.id,
      userId: props.user.id,
      roleId: roleId,
      resourceId: resourceId
    });
  } catch {
    applicationStore.publishErrorNotification({
      text: 'Something went wrong, please try again.',
      autoCloseMs: 3000,
    });
    isRevoking.value = false;
    return;
  }

  emit('roles-updated');
  isRevoking.value = false;
}

const revokeRoleAction = async (roleId: string, resourceId: string): Promise<void> => {
  const lastRole = props.user.roles.length === 1;

  // Block revoking if it's the last role and we are not showing a confirmation
  if (lastRole && !showConfirmRevokeLastRole.value) {
    showConfirmRevokeLastRole.value = true;
    lastRoleRef.value = {
      roleId: roleId,
      resourceId: resourceId,
    };
    return;
  }

  await revokeApiRole(roleId, resourceId);

  showConfirmRevokeLastRole.value = false;
  lastRoleRef.value = undefined;

  if (lastRole) {
    await router.replace({ name: PageNames.Users });
    return;
  }
}

const rolesResourceName = (relId: string, relType: client.Resources): string | undefined  => {
  if (relType === client.Resources.VIEW) {
    const view = props.views.find((view) => view.id === relId);
    return `: ${view?.name}`;
  }
  if (relType === client.Resources.PROJECT) {
    const project = props.projects.find((project) => project.id === relId);
    return `: ${project?.name}`;
  }
  return;
}

const hideConfirmRevokeLastRole = (): void => {
  showConfirmRevokeLastRole.value = false
  isRevoking.value = false;
}
</script>

<template>
  <ModalComponent :visible="showConfirmRevokeLastRole"
                  heading-title="Revoke Role"
                  @on-close="hideConfirmRevokeLastRole">
    <template #modal-content>
      <p>
        Are you sure you want to revoke this Role, this will remove all access to your organisation from this user?
      </p>

      <p>
        To add the user back in to your organisation, you will need send them an Invite again.
      </p>
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="hideConfirmRevokeLastRole">
        Cancel
      </ButtonComponent>
      <ButtonComponent :is-block-btn="true"
                       :variant="ButtonVariant.Danger"
                       @click="revokeRoleAction(lastRoleRef?.roleId!, lastRoleRef?.resourceId!)">
        Confirm
      </ButtonComponent>
    </template>
  </ModalComponent>

  <form>
    <div class="field-group">
      <div class="field-group-info">
        <Heading level="3">
          Assigned Roles
        </Heading>
        <p>Roles that this user has within your organisation.</p>
      </div>

      <div class="fields">
        <div class="row">
          <div class="field">
            <BadgeComponent v-for="role in props.user.roles"
                            :key="role.id"
                            :is-pill="true"
                            :variant="BadgeVariant.Light"
                            style="margin: 0.25rem; white-space: normal">
              {{ role.shortName }} {{ rolesResourceName(role.relId, role.relType as client.Resources) }}
              <ButtonComponent :disabled="isLoading || isRevoking"
                               :icon-name="IconName.XCircleIcon"
                               :icon-style="IconStyle.Solid"
                               :is-icon-btn="true"
                               :is-outline-btn="true"
                               aria-label="Revoke role"
                               :variant="ButtonVariant.Link"
                               @click="() => revokeRoleAction(role.id, role.relId)" />
            </BadgeComponent>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
