<script setup lang="ts">
import { useApplicationStore } from '@stores/application';
import { BreadcrumbPaths, BreadcrumbTitles, ButtonVariant } from '@viewModels/enums';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import SubHeader from '@components/SubHeader.vue';
import EditUserForm from '@components/account_forms/EditUserForm.vue';

const applicationStore = useApplicationStore();
const { activeUser } = applicationStore;

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllViews, to: BreadcrumbPaths.Views },
  { title: BreadcrumbTitles.UserInformation, active: true },
];
</script>

<template>
  <SubHeader heading="Account"
             level="2">
    <template #buttons>
      <ButtonComponent :is-block-btn="true"
                       to="/logout"
                       :variant="ButtonVariant.Dark">
        Logout
      </ButtonComponent>
      <ButtonComponent :is-block-btn="true"
                       to="/ChangePassword"
                       :variant="ButtonVariant.Dark">
        Change Password
      </ButtonComponent>
    </template>
  </SubHeader>

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <EditUserForm v-if="activeUser"
                :user-id="activeUser.id"
                @on-updated="applicationStore.setActiveUser()" />
</template>
