import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

import { useApplicationStore } from '@stores/application';
import { PageNames } from '@viewModels/enums';

export default async function (to: RouteLocationNormalized): Promise<RouteLocationRaw | void> {
  if (to.meta['public']) return;

  const applicationStore = useApplicationStore();
  const activeOrganisation = applicationStore.activeOrganisation;

  if (activeOrganisation) return;
  if (!applicationStore.activeUser || !applicationStore.activeUser.organisations?.length) {
    return { name: PageNames.Logout, query: { message: 'No organisation found.' } };
  }

  if (applicationStore.organisationList && applicationStore.organisationList?.length > 1) {
    await applicationStore.setActiveOrganisation(applicationStore.organisationList[0]!.id);
  }
  return;
}
