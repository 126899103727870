<script setup lang="ts">
import { ref, watch } from 'vue';

import { ButtonSize, ButtonVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';

/*
TODO - Change this to emit and not take a function as a prop
*/
/**
 * We need an optional id reference, and a search function
 */
const props = defineProps({
  onSearchFunction: {
    type: Function,
    default: () => {},
  },
  onClearFunction: {
    type: Function,
    default: () => {},
  },
});

/**
 * A push event to notify the parents of when the selector is activated
 */
const push = defineEmits(['onSearched']);

/**
 * The name of the viewer we want to find
 */
const viewerName = ref<string>();

/**
 * This method is used to search filter the viewers we need
 */
async function onSearch(): Promise<void> {
  if (viewerName.value && props.onSearchFunction) {
    const outcome = await props.onSearchFunction(viewerName.value);
    push('onSearched', outcome);
  }
}

/**
 * We create a props watch to override the internal index if needed
 */
watch(
  () => viewerName.value,
  async (newValue) => {
    if (newValue === '' && props.onClearFunction) {
      await props.onClearFunction();
    }
  }
);
</script>

<template>
  <div class="search">
    <input v-model="viewerName"
           type="search"
           placeholder="&nbsp;"
           @keydown.enter="onSearch">

    <ButtonComponent :variant="ButtonVariant.Dark"
                     :size="ButtonSize.Small"
                     @click="onSearch">
      Search
    </ButtonComponent>
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.search {
  position: relative;

  @media screen and (min-width: $breakpoint-lg) {
    margin-left: auto;
  }

  &::before {
    position: absolute;
    top: 7px;
    left: 8px;
    z-index: 1;
    width: 26px;
    height: 26px;
    pointer-events: none;
    content: '';
    background-image: url('/src/assets/heroIcons/search.svg');
    filter: #{'invert(30%)'};
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  input[type='search'] {
    box-sizing: content-box;
    width: 140px;
    height: 24px;
    padding: 7px 80px 8px 38px;
    font-size: 1em;
    appearance: textfield;
    background-color: $neutral-50;
    border: none;
    border-radius: 5px;
    outline: none;
    box-shadow: 0 0 0 1px $neutral-600;
    transition: width 0.15s ease;

    @media screen and (width >= 1060px) {
      width: 90px;
    }

    @media screen and (width >= 1110px) {
      width: 140px;
    }

    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration {
      appearance: none;
    }

    &:placeholder-shown {
      width: 5px;
      padding: 7px 0 8px 34px;

      + button {
        visibility: hidden;
        opacity: 0;
      }
    }

    &:focus,
    &:focus-visible {
      width: 140px;
      padding: 7px 80px 8px 38px;

      @media screen and (width >= 1060px) {
        width: 90px;
      }

      @media screen and (width >= 1110px) {
        width: 140px;
      }
    }
  }

  button {
    position: absolute;
    top: 4px;
    right: 5px;
    visibility: visible;
    opacity: 1;
  }
}
</style>
