<script setup lang="ts">
import { computed, ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import { Entitlements } from '@gabrielcam/api-client';
import { ButtonVariant } from '@viewModels/enums';
import Heading from '@components/Heading.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import { useApplicationStore } from '@stores/application';
import { dateTimeFormat } from '@utils/date';

// Gallery Card Interface
const props = defineProps<{
  resource: client.Image | null; // Accept null initially to indicate loading state
  selected?: boolean;
}>();

const emit = defineEmits<{
  (e: 'showImageModal'): void;
  (e: 'onDelete', value: client.Image): void;
}>();

// Stores
const applicationStore = useApplicationStore();
const { activeUser } = applicationStore;

// Permissions
const showDelete = applicationStore.canUser(Entitlements.DELETE_IMAGE, applicationStore.activeOrganisation!);

// Image References
const imageFileName = ref(props.resource?.originalFileName);
const imageThumbnailUrl = ref(props.resource?.sourceURL);
const imageCapturedAt = computed(() =>
  props.resource?.capturedAt
    ? dateTimeFormat(activeUser?.timezone).format(new Date(props.resource?.capturedAt))
    : 'Never',
);

/**
 * Downloads a single image by constructing the download URL from imageThumbnailUrl and imageFileName.
 *
 * @returns {void} This function does not return anything.
 */
const downloadSingleImage = (): void => {
  // Construct the download URL
  const url = `${imageThumbnailUrl.value}?dl=${imageFileName.value}`;

  // Trigger the download
  const link: HTMLAnchorElement = document.createElement('a');
  link.href = url;
  link.setAttribute('download', imageFileName.value || '');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  applicationStore.publishSuccessNotification({
    text: 'Image downloading, please check your browser’s downloads folder',
    autoCloseMs: 3000,
  });
};


function handleDeleteClick(): void {
  const resource = props.resource;
  if (resource) {
    emit('onDelete', resource as client.Image);
  } else {
    console.error('Resource not emitted from Gallery Card');
  }
}
</script>

<template>
  <div class="gallery-card__container">
    <div class="gallery-card">
      <div class="gallery-card__header">
        <Heading level="4" class="gallery-card__header--title">
          {{ imageFileName }}
        </Heading>
      </div>

      <div class="gallery-card__image">
        <!-- Show a fallback image if imageThumbnailUrl fails -->
        <img v-if="imageThumbnailUrl"
             :src="`${imageThumbnailUrl}/thumbnail`"
             aria-hidden="true"
             :alt="`${imageFileName} image`"
             @click="emit('showImageModal')">
        <template v-if="!imageThumbnailUrl">
          <p class="gallery-card__image--fallback">
            Image failed to load, please refresh the page or try again.
          </p>
        </template>
      </div>

      <div class="gallery-card__content">
        <div>
          <span class="text--bold">Captured: </span>
          <span>{{ imageCapturedAt }}</span>
        </div>
      </div>

      <div class="gallery-card__actions">
        <ButtonComponent :variant="ButtonVariant.Primary"
                         :is-icon-btn="true"
                         :icon-name="IconName.MagnifyingGlassIcon"
                         :icon-style="IconStyle.Outline"
                         :disabled="!imageThumbnailUrl"
                         @click="emit('showImageModal')" />
        <ButtonComponent :variant="ButtonVariant.Primary"
                         :is-icon-btn="true"
                         :icon-name="IconName.ArrowDownTrayIcon"
                         :icon-style="IconStyle.Outline"
                         aria-label="Download image"
                         :disabled="!imageThumbnailUrl"
                         @click="downloadSingleImage" />
        <ButtonComponent v-if="false"
                         :style="!imageThumbnailUrl ? 'margin-left: auto' : ''"
                         :variant="ButtonVariant.Danger"
                         :is-icon-btn="true"
                         :icon-name="IconName.TrashIcon"
                         :icon-style="IconStyle.Outline"
                         aria-label="Delete"
                         @click="handleDeleteClick" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/scss/variables' as *;

.gallery-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  padding: $gap-mobile;
  background-color: $neutral-100;
  border-radius: 10px;
  box-shadow: inset 0 0 0 1px $neutral-300;
  transition: background-color 300ms ease, box-shadow 300ms ease;

  &__container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__header {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding-block: 5px;
    margin-bottom: $margin-bottom;

    &--title {
      display: -webkit-box;
      overflow: hidden;
      line-height: 1.2;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;

      @media screen and (min-width: $breakpoint-sm) {
        flex-basis: 2.4em;
      }
    }
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;

    // Use aspect ratio to stop CLS (Content Layout Shift)
    aspect-ratio: 16 / 9;
    margin-bottom: 5px;
    overflow: hidden;
    background: $neutral-200 url('/src/assets/background-light.png') repeat center center;
    background-size: 25%;
    border-radius: 10px;
    box-shadow: 0 0 0 1px $neutral-300;

    & img {
      max-width: 100%;
      height: auto;
      overflow: hidden;
      object-fit: cover;
      border-radius: 10px;
      transition: transform 200ms ease-in;

      &:hover {
        cursor: pointer;
        transform: scale(1.05);
      }
    }

    &--fallback {
      padding-inline: $gap-desktop;
      font-size: .875rem;
      color: $neutral-600;
      text-align: center;
    }
  }

  &__content {
    margin-bottom: $margin-bottom;
    font-size: .875rem;
    color: $neutral-600;
  }

  &__actions {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    justify-content: space-between;
  }
}
</style>