<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ButtonVariant, PageNames } from '@viewModels/enums';

// import * as client from '@gabrielcam/api-client';
// import { useApplicationStore } from '@stores/application';
import NameSearch from '@components/toolbar/NameSearch.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';

const emit = defineEmits<{
  (e: 'selectClient', value: string): void;
  (e: 'search', search: string | undefined, sort: string): any;
}>();

const props = defineProps({
  defaultSortBy: { type: String, required: false, default: 'lastCaptured' },
});

const route = useRoute();
const router = useRouter();

// const applicationStore = useApplicationStore();
// const clients = ref([]) as Ref<client.Client[]>;
const filterDropdownOpen = ref<boolean>(false);
const sortDropdownOpen = ref<boolean>(false);
const selectedSortBy = ref<string>(
  props.defaultSortBy.startsWith('-') ? props.defaultSortBy.slice(1) : props.defaultSortBy
);
const selectedSortOrder = ref<string>(props.defaultSortBy.startsWith('') ? '' : '-');
const selectedSearchValue = ref<string>();

const isViewBtnDisabled = computed(() => route.name === PageNames.Views);
const isMapViewBtnDisabled = computed(() => route.name === PageNames.ViewMap);

const handleNavigation = (name: string, disabled: boolean): void => {
  if (!disabled) {
    router.push({ name });
  }
};

// async function obtainClientList(): Promise<void> {
//   const res = await client.listClients({ organisation: applicationStore.activeOrganisation!.id });
//   clients.value = res.data;
// }

// function toggleFilterDropdown(): void {
//   obtainClientList();
//   sortDropdownOpen.value = false;
//   filterDropdownOpen.value = !filterDropdownOpen.value;
// }
function toggleSortDropdown(): void {
  filterDropdownOpen.value = false;
  sortDropdownOpen.value = !sortDropdownOpen.value;
}

const sortOptions = [
  { value: 'name', displayValue: 'Name' },
  { value: 'lastCaptured', displayValue: 'Latest Image' },
];
const sortOrderOptions = [
  { value: '', displayValue: 'A-Z or Low to High' },
  { value: '-', displayValue: 'Z-A or High to Low' },
];

function updateSort(sortBy: string, sortOrder: string): void {
  emit('search', selectedSearchValue.value, `${sortOrder}${sortBy}`);
}
function search(searchValue: string): void {
  selectedSearchValue.value = searchValue;
  updateSort(selectedSortBy.value, selectedSortOrder.value);
}
</script>

<template>
  <div class="filters">
    <div class="filters__button-group">
      <ButtonComponent :icon-style="IconStyle.Outline"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.PhotoIcon"
                       :is-outline-btn="!isViewBtnDisabled"
                       :is-icon-btn="true"
                       :disabled="isViewBtnDisabled"
                       :variant="ButtonVariant.Dark"
                       @click="handleNavigation(PageNames.Views, isViewBtnDisabled)">
        View
      </ButtonComponent>
      <ButtonComponent :icon-style="IconStyle.Outline"
                       :icon-position="IconPosition.Right"
                       :icon-name="IconName.MapPinIcon"
                       :is-outline-btn="!isMapViewBtnDisabled"
                       :is-icon-btn="true"
                       :variant="ButtonVariant.Dark"
                       :disabled="isMapViewBtnDisabled"
                       @click="handleNavigation(PageNames.ViewMap, isMapViewBtnDisabled)">
        Map View
      </ButtonComponent>
    </div>

    <div class="filters__dropdown">
      <ButtonComponent :icon-style="IconStyle.Outline"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.ChevronDownIcon"
                       :is-outline-btn="true"
                       :variant="ButtonVariant.Dark"
                       class="filters__dropdown--btn"
                       :class="{ 'filters__dropdown--open': sortDropdownOpen }"
                       @click="toggleSortDropdown">
        Sort
      </ButtonComponent>

      <div class="filters__dropdown--content">
        <div class="content-item">
          <p class="content-item-label">
            Sort on
          </p>
          <v-select v-model="selectedSortBy"
                    placeholder="Name"
                    label="displayValue"
                    :reduce="(value: any) => value.value"
                    :options="sortOptions"
                    :clearable="false"
                    @update:model-value="(value: string) => updateSort(value, selectedSortOrder)" />
        </div>
        <div class="content-item">
          <p class="content-item-label">
            Sort by
          </p>
          <v-select v-model="selectedSortOrder"
                    placeholder="A-Z or Low to High"
                    label="displayValue"
                    :reduce="(value: any) => value.value"
                    :options="sortOrderOptions"
                    :clearable="false"
                    @update:model-value="(value: string) => updateSort(selectedSortBy, value)" />
        </div>
      </div>
    </div>

    <!--  TODO: Filters - Class names have changed, just update
    <div class="filters-filter dropdown-toggle">
      <a href="javascript:;" class="icon icon-filter" :class="{ 'dropdown-open': filterDropdownOpen == true }" @click="toggleFilterDropdown">Filter <span class="count">0</span></a
      >
      <div class="dropdown">
        <div class="dropdown-item">
          <p class="dropdown-item-label">Client</p>
          <v-select
            placeholder="All clients"
            label="name"
            :options="clients"
            @update:modelValue="(client: client.Client) => emit('selectClient', client?.id)"
          ></v-select>
        </div>
        <div class="dropdown-item">
          <p class="dropdown-item-label">Status</p>
          <v-select placeholder="All statuses" :options="['OK', 'Warning', 'Error', 'Fatal']"></v-select>
        </div>
        <div class="dropdown-item">
          <p class="dropdown-item-label">Stage</p>
          <v-select placeholder="All stages" :options="['Live', 'Complete', 'Testing', 'Paused', 'Unknown']"></v-select>
        </div>
      </div>
    </div>
-->
    <NameSearch :on-search-function="search"
                :on-clear-function="search" />
  </div>
</template>

<!-- Also used in VideoDirectoryFilter -->
<style lang="scss">
@use '@scss/variables' as *;

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: $gap-mobile;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: $gap-desktop;

  @media screen and (min-width: $breakpoint-lg) {
    gap: $gap-desktop;
    justify-content: start;
  }

  &__button-group {
    display: flex;

    button:first-of-type {
      border-radius: 5px 0 0 5px;
    }

    button:last-of-type {
      border-radius: 0 5px 5px 0;
    }
  }

  &__dropdown {
    @media screen and (min-width: $breakpoint-lg) {
      position: relative;
    }

    &--btn .btn__icon {
      transition: transform 0.3s ease-in-out;
    }

    &--btn.filters__dropdown--open .btn__icon {
      transform: rotate(180deg);
    }

    &--content {
      position: absolute;
      left: 0;
      z-index: 2;
      width: calc(100% - 30px);
      padding: 15px;
      margin: 10px 0 0 15px;
      visibility: hidden;
      background: $neutral-50;
      border: 1px solid $neutral-300;
      border-radius: 5px;
      box-shadow: $box-shadow;
      opacity: 0;
      transition: opacity 0.15s ease,
      transform 0.15s ease,
      visibility 0.15s;
      transform: translateY(-10px);

      @media screen and (min-width: $breakpoint-lg) {
        left: -15px;
        min-width: 400px;
        padding: 30px;
      }

      & .content-item {
        display: flex;
        gap: 15px;
        align-items: center;
        margin-bottom: 10px;

        &-label {
          margin: 0;
        }

        & .v-select {
          flex-grow: 1;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &--open {
      + .filters__dropdown--content {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
</style>
