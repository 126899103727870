<script lang="ts" setup>
import { computed, PropType, ref, watch } from 'vue';
import { Line } from 'vue-chartjs';

import {
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-date-fns';

import * as client from '@gabrielcam/api-client';

import { reduceData } from '@utils/dataUtils';
import { useMobileDetection } from '@utils/isMobile';

import Loading from '@components/Loading.vue';

const { isMobile } = useMobileDetection();

const props = defineProps({
  stats: {
    type: Object as PropType<client.CameraStat[]>,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
  unauthorised: {
    type: Boolean,
    required: false,
  },
});

class MeasurementViewModel {
  x: Date = new Date();
  y = 0;
}

const RSNSI = 3;
const inputVoltages = ref<MeasurementViewModel[]>([]);
// const inputCurrents = ref<MeasurementViewModel[]>([]);
// const batteryCurrents = ref<MeasurementViewModel[]>([]);
const ina219ShuntCurrents = ref<MeasurementViewModel[]>([]);
const watts = ref<MeasurementViewModel[]>([]);
const data = ref();

const pastelColors: string[] = [
  '#223171',
  '#2d4197',
  '#4361e3',
  '#9a0c22',
  '#cd102e',
  '#17214b',
  '#da4c63',
  '#166534',
  '#26b15b',
  '#36fd82',
  '#000000',
  '#333333',
  '#888888',
  '#555555',
  '#cccccc',
];

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale
);

// Desktop View Options
const options: ChartOptions<'line'> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'hour',
        tooltipFormat: 'dd/MM/yyyy HH:mm',
        displayFormats: {
          hour: 'HH:mm',
        },
      },
      ticks: {
        source: 'auto',
      },
      grid: {
        display: true,
      },
    },
    y: {
      type: 'linear',
      position: 'left',
      title: {
        display: true,
        text: 'Power (W)',
      },
      ticks: {
        padding: 10,
        callback: function (value: string | number): string {
          if (typeof value === 'number') {
            return value.toFixed(1) + 'w';
          }
          return value.toString();
        },
      },
      grid: {
        drawOnChartArea: false,
      },
    },
    y2: {
      type: 'linear',
      position: 'left',
      title: {
        display: true,
        text: 'Current (mA)',
      },
      ticks: {
        padding: 10,
        callback: function (value: string | number): string {
          if (typeof value === 'number') {
            return value.toFixed(2) + 'mA';
          }
          return value.toString();
        },
      },
      grid: {
        drawOnChartArea: false,
      },
    },
    y3: {
      type: 'linear',
      position: 'left',
      title: {
        display: true,
        text: 'Voltage (V)',
      },
      ticks: {
        padding: 10,
        callback: function (value: string | number): string {
          if (typeof value === 'number') {
            return value.toFixed(1) + 'v';
          }
          return value.toString();
        },
      },
    },
  },
  plugins: {
    tooltip: {
      mode: 'index',
      intersect: false,
    },
    title: {
      display: true,
      text: 'Power Status',
      position: 'top',
      align: 'center',
      color: '#262626',
      font: {
        size: 18,
        family: 'Poppins, sans-serif',
      },
      padding: {
        top: 20,
        bottom: 20,
      },
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        useBorderRadius: true,
        borderRadius: 2,
        padding: 20,
        color: '#262626',
        font: {
          size: 14,
          family: 'Poppins, sans-serif',
        },
      },
    },
  },
};

// Mobile View Options
const mobileOptions: Partial<ChartOptions<'line'>> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'hour',
        tooltipFormat: 'dd/MM/yyyy HH:mm',
        displayFormats: {
          hour: 'HH:mm',
        },
      },
      ticks: {
        source: 'auto',
      },
      grid: {
        display: true,
      },
    },
    y: {
      display: false,
    },
    y2: {
      display: false,
    },
    y3: {
      display: false,
    },
  },
};

// Merge Chart Options based on isMobile
const finalOptions = computed<ChartOptions<'line'>>(() => (isMobile ? { ...options, ...mobileOptions } : options));

watch(
  () => props.stats,
  () => {
    inputVoltages.value = reduceData(
      props.stats.map((stat) => ({
        x: new Date(stat.timestamp),
        y: stat.lt4015InputVoltage * 0.001 * 1.648,
      }))
    );

    watts.value = reduceData(
      props.stats.map((stat) => ({
        x: new Date(stat.timestamp),
        y: ((stat.lt4015InputCurrent * (1.46487 / RSNSI)) / 1000) * (stat.lt4015InputVoltage * 0.001 * 1.648),
      }))
    );

    ina219ShuntCurrents.value = reduceData(
      props.stats.map((stat) => ({
        x: new Date(stat.timestamp),
        y: stat.ina219ShuntVoltage / 1000000 / 0.025,
      }))
    );

    data.value = {
      datasets: [
        {
          label: 'Voltage (V)',
          backgroundColor: pastelColors[1],
          data: inputVoltages.value,
          borderWidth: 2,
          borderColor: pastelColors[1],
          xAxisID: 'x',
          yAxisID: 'y',
          cubicInterpolationMode: 'monotone',
        },
        {
          label: 'Current (mA)',
          backgroundColor: pastelColors[7],
          data: ina219ShuntCurrents.value,
          borderWidth: 2,
          borderColor: pastelColors[7],
          xAxisID: 'x',
          yAxisID: 'y2',
          cubicInterpolationMode: 'monotone',
        },
        {
          label: 'Power (W)',
          backgroundColor: pastelColors[12],
          data: watts.value,
          borderWidth: 2,
          borderColor: pastelColors[12],
          xAxisID: 'x',
          yAxisID: 'y3',
          cubicInterpolationMode: 'monotone',
        },
      ],
    };
  },
  { immediate: true }
);
</script>

<template>
  <div class="mb-20">
    <Loading v-if="loading" />

    <div v-else-if="unauthorised"
         class="unauthorised-content">
      <b>Power</b>
      <p>Unauthorised</p>
    </div>

    <div v-else>
      <Line id="chart-power"
            :data="data"
            :options="finalOptions"
            :style="{ height: isMobile ? '400px' : '500px' }" />
    </div>
  </div>
</template>
