<script setup lang="ts">
import { onBeforeUnmount, onMounted, watch } from 'vue';

import { ButtonVariant } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';

import ButtonComponent from '@components/ButtonComponent.vue';
import Heading from '@components/Heading.vue';

const props = defineProps<{
  visible: boolean;
  headingTitle: string;
  isLarge?: boolean;
}>();

const emit = defineEmits(['onClose']);

// Close modal and emit event
const closeModal = (): void => emit('onClose');

// Toggle body scroll based on modal visibility
const toggleBodyScroll = (disable: boolean): void => {
  document.body.style.overflow = disable ? 'hidden' : '';
};

// Watch for visibility changes and toggle body scroll
watch(() => props.visible, toggleBodyScroll);

// Cleanup body scroll on component unmount
onBeforeUnmount(() => toggleBodyScroll(false));

// Handle initial state on component mount
onMounted(() => {
  if (props.visible) toggleBodyScroll(true);
});
</script>

<template>
  <div id="modal"
       class="modal"
       :class="{ 'modal--open': visible }"
       role="dialog"
       aria-modal="true"
       aria-labelledby="modal-heading"
       @click.self="closeModal">
    <div :class="['modal__content', { 'modal__content--large': isLarge }]"
         role="document">
      <div class="modal__content--header">
        <Heading level="2">
          {{ headingTitle }}
        </Heading>

        <ButtonComponent aria-label="Close modal"
                         :icon-name="IconName.XMarkIcon"
                         :icon-style="IconStyle.Outline"
                         :is-outline-btn="true"
                         class="close-btn"
                         :variant="ButtonVariant.Link"
                         :is-icon-btn="true"
                         @click="emit('onClose')" />
      </div>

      <div class="modal__content--body">
        <slot name="modal-content" />
      </div>

      <template v-if="$slots['modal-footer']">
        <div class="modal__content--footer">
          <slot name="modal-footer" />
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.modal {
  position: fixed;
  inset: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: $black-opacity-75;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &--open {
    pointer-events: auto;
    opacity: 1;
  }

  &__content {
    position: relative;
    width: min(800px, calc(100% - 30px));
    background-color: $neutral-50;
    border-radius: 8px;

    &--large {
      width: min(1400px, calc(100% - 30px));
    }

    &--header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px clamp(15px, 3vw, 30px);
      color: $neutral-800;
      background-color: $neutral-200;
      border-bottom: 1px solid $neutral-300;
      border-radius: 10px 10px 0 0;

      & .close-btn {
        width: 50px;
        height: 50px;
        transition: all 0.15s ease;
      }
    }

    &--body {
      height: 100%;
      min-height: 20vh;
      max-height: 60vh;
      padding: 20px clamp(15px, 3vw, 30px);
      overflow: auto;
      color: $neutral-800;
    }

    &--footer {
      display: flex;
      flex-direction: column-reverse;
      gap: $gap-default;
      min-height: 80px;
      padding: 20px clamp(15px, 3vw, 30px);
      background-color: $neutral-200;
      border-top: 1px solid $neutral-300;
      border-radius: 0 0 10px 10px;

      @media screen and (min-width: $breakpoint-lg) {
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }
}
</style>
