<script lang="ts" setup>
import { computed, PropType, ref, watch } from 'vue';
import { Bar } from 'vue-chartjs';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chartjs-adapter-date-fns';

import * as client from '@gabrielcam/api-client';

import { reduceData } from '@utils/dataUtils';
import { useMobileDetection } from '@utils/isMobile';

import Loading from '@components/Loading.vue';

const { isMobile } = useMobileDetection();

const props = defineProps({
  stats: {
    type: Object as PropType<client.CameraStat[]>,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
  },
  unauthorised: {
    type: Boolean,
    required: false,
  },
});

class MeasurementViewModel {
  x: Date = new Date();
  y = '';
}

const heaterOn = ref<MeasurementViewModel[]>([]);
const cameraOn = ref<MeasurementViewModel[]>([]);
const piOn = ref<MeasurementViewModel[]>([]);
const data = ref();

const pastelColors: string[] = [
  '#223171',
  '#2d4197',
  '#4361e3',
  '#9a0c22',
  '#cd102e',
  '#17214b',
  '#da4c63',
  '#166534',
  '#26b15b',
  '#36fd82',
  '#000000',
  '#333333',
  '#888888',
  '#555555',
  '#cccccc',
];

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeScale, TimeSeriesScale);

// Desktop View Options
const options: ChartOptions<'bar'> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'hour',
        tooltipFormat: 'dd/MM/yyyy HH:mm',
        displayFormats: {
          hour: 'HH:mm',
        },
      },
      ticks: {
        source: 'auto',
      },
      grid: {
        display: true,
      },
    },
    y: {
      type: 'linear',
      position: 'left',
      title: {
        display: true,
        text: 'Power (On | Off)',
      },
      ticks: {
        padding: 10,
        stepSize: 1,
        callback: function (value: string | number): string {
          if (typeof value === 'number') {
            if (value === 1) return 'ON';
            if (value === 0) return 'OFF';
          }
          return value.toString();
        },
      },
      min: 0,
      max: 1,
      grid: {
        drawOnChartArea: false,
      },
    },
  },
  plugins: {
    tooltip: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function (context: any) {
          const value = Number(context.raw.y); // Convert the y value to a number
          let label = context.dataset.label || '';
          if (label) {
            label += ': ';
          }
          if (value === 1) {
            label += 'ON';
          } else if (value === 0) {
            label += 'OFF';
          } else {
            label += value.toString();
          }
          return label;
        },
      },
    },
    title: {
      display: true,
      text: 'Equipment Status',
      position: 'top',
      align: 'center',
      color: '#262626',
      font: {
        size: 18,
        family: 'Poppins, sans-serif',
      },
      padding: {
        top: 20,
        bottom: 20,
      },
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        useBorderRadius: true,
        borderRadius: 2,
        padding: 20,
        color: '#262626',
        font: {
          size: 14,
          family: 'Poppins, sans-serif',
        },
      },
    },
  },
};

// Mobile View Options
const mobileOptions: Partial<ChartOptions<'bar'>> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'hour',
        tooltipFormat: 'dd/MM/yyyy HH:mm',
        displayFormats: {
          hour: 'HH:mm',
        },
      },
      ticks: {
        source: 'auto',
      },
      grid: {
        display: true,
      },
    },
    y: {
      display: false,
    },
  },
};

// Merge Chart Options based on isMobile
const finalOptions = computed<ChartOptions<'bar'>>(() => (isMobile ? { ...options, ...mobileOptions } : options));

watch(
  () => props.stats,
  () => {
    heaterOn.value = reduceData(
      props.stats.map((stat) => ({
        x: new Date(stat.timestamp),
        y: (stat.heaterOn === true ? 1 : 0).toString(),
      }))
    );

    piOn.value = reduceData(
      props.stats.map((stat) => ({
        x: new Date(stat.timestamp),
        y: (stat.piOn === true ? 1 : 0).toString(),
      }))
    );

    cameraOn.value = reduceData(
      props.stats.map((stat) => ({
        x: new Date(stat.timestamp),
        y: (stat.cameraOn === true ? 1 : 0).toString(),
      }))
    );

    data.value = {
      name: 'Equipment',
      components: {
        Bar,
      },
      datasets: [
        {
          label: 'Heater',
          backgroundColor: pastelColors[4],
          data: heaterOn.value,
          xAxisID: 'x',
          yAxisID: 'y',
          borderWidth: 0,
        },
        {
          label: 'Camera',
          backgroundColor: pastelColors[7],
          data: cameraOn.value,
          xAxisID: 'x',
          yAxisID: 'y',
          borderWidth: 0,
        },
        {
          label: 'Module',
          backgroundColor: pastelColors[8],
          data: piOn.value,
          xAxisID: 'x',
          yAxisID: 'y',
          borderWidth: 0,
        },
      ],
    };
  },
  { immediate: true }
);
</script>

<template>
  <div class="mb-20">
    <Loading v-if="loading" />

    <div v-else-if="unauthorised"
         class="unauthorised-content">
      <b>Equipment</b>
      <p>Unauthorised</p>
    </div>
    <Bar v-else
         id="chart-equipment"
         :data="data"
         :options="finalOptions"
         :style="{ height: isMobile ? '300px' : '300px' }" />
  </div>
</template>
