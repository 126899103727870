import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';
import * as client from '@gabrielcam/api-client';
import { PageNames } from '@viewModels/enums';

/**
 * Checks if a view is publicly accessible and updates the route meta accordingly.
 * If the view is not public, redirects to the login page.
 *
 * @param to - The route location being navigated to.
 * @returns A promise that resolves to the updated route location or a redirect to the login page.
 */
export default async function checkPublicView(to: RouteLocationNormalized): Promise<RouteLocationRaw | void> {
  try {
    const view = await client.getViewById({ viewId: to.params['id'] as string });

    // If the view isPublic: true, set the public meta property to true
    if (view?.isPublic) {
      to.meta['public'] = true;
      return;
    }

  } catch (error) {
    console.error('Error fetching view:', error);
  }
  return { name: PageNames.Login, query: { continueUrl: to.path } };
}
