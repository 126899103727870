<script setup lang="ts">
import { ref, watch } from 'vue';

// import * as client from '@gabrielcam/api-client';
// import { useApplicationStore } from '@stores/application';
import { useVideoStore } from '@stores/video';

import NameSearch from '@components/toolbar/NameSearch.vue';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';
import { ButtonVariant } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';

const emit = defineEmits<{
  (e: 'sort-view-by', sortBy: string, sortOrder: string): void;
}>();

// const applicationStore = useApplicationStore();
const videoStore = useVideoStore();

// const clients = ref([]) as Ref<client.Client[]>;
// const views = ref([]) as Ref<client.View[]>;
const filterDropdownOpen = ref<boolean>(false);
const sortDropdownOpen = ref<boolean>(false);
const selectedSortBy = ref<string>('createdAt');
const selectedSortOrder = ref<string>('-');

// async function obtainViewList(): Promise<void> {
//   const res = await client.listViews({ organisation: applicationStore.activeOrganisation!.id });
//   views.value = res.data;
// }

// async function obtainClientList(): Promise<void> {
//   const res = await client.listClients({ organisation: applicationStore.activeOrganisation!.id });
//   clients.value = res.data;
// }

// function toggleFilterDropdown(): void {
//   obtainClientList();
//   obtainViewList();
//   sortDropdownOpen.value = false;
//   filterDropdownOpen.value = !filterDropdownOpen.value;
// }

function toggleSortDropdown(): void {
  filterDropdownOpen.value = false;
  sortDropdownOpen.value = !sortDropdownOpen.value;
}

// async function selectClient(clientId: string): Promise<void> {
//   videoStore.selectedFilters.client = clientId;
//   await videoStore.getVideos();
// }

// async function selectView(viewId: string): Promise<void> {
//   videoStore.selectedFilters.view = viewId;
//   await videoStore.getVideos();
// }

const sortOptions = [
  { value: 'name', displayValue: 'Name' },
  { value: 'createdAt', displayValue: 'Created Date' },
];
const sortOrderOptions = [
  { value: '-', displayValue: 'A-Z or Low to High' },
  { value: '', displayValue: 'Z-A or High to Low' },
];

function updateSort(sortBy: string, sortOrder: string): void {
  emit('sort-view-by', sortBy, sortOrder);
}

// Watch for changes in selectedSortBy and selectedSortOrder
watch([selectedSortBy, selectedSortOrder], ([newSortBy, newSortOrder]) => {
  updateSort(newSortBy, newSortOrder);
});
</script>

<template>
  <div class="filters">
    <div class="filters__dropdown">
      <ButtonComponent :icon-style="IconStyle.Outline"
                       :icon-position="IconPosition.Left"
                       :icon-name="IconName.ChevronDownIcon"
                       :is-outline-btn="true"
                       :variant="ButtonVariant.Dark"
                       class="filters__dropdown--btn"
                       :class="{ 'filters__dropdown--open': sortDropdownOpen }"
                       @click="toggleSortDropdown">
        Sort
      </ButtonComponent>

      <div class="filters__dropdown--content">
        <div class="content-item">
          <p class="content-item-label">
            Sort on
          </p>
          <v-select v-model="selectedSortBy"
                    placeholder="Name"
                    label="displayValue"
                    :reduce="(value: any) => value.value"
                    :options="sortOptions"
                    :clearable="false" />
        </div>
        <div class="content-item">
          <p class="content-item-label">
            Sort by
          </p>
          <v-select v-model="selectedSortOrder"
                    placeholder="A-Z or Low to High"
                    label="displayValue"
                    :reduce="(value: any) => value.value"
                    :options="sortOrderOptions"
                    :clearable="false" />
        </div>
      </div>
    </div>
    <!--    TODO: Filters - Class names have changed, just update -->
    <!-- <div class="filters-filter dropdown-toggle">
      <a href="javascript:;" class="icon icon-filter" :class="{ 'dropdown-open': filterDropdownOpen == true }" @click="toggleFilterDropdown">Filter <span class="count">0</span></a
      >
      <div class="dropdown">
        <div class="dropdown-item">
          <p class="dropdown-item-label">Client</p>
          <v-select
            placeholder="All clients"
            label="name"
            :options="clients"
            @update:modelValue="(client: client.Client) => selectClient(client?.id)"
          ></v-select>
        </div>
        <div class="dropdown-item">
          <p class="dropdown-item-label">View</p>
          <v-select
            placeholder="All views"
            label="name"
            :options="views"
            @update:modelValue="(view: client.View) => selectView(view?.id)"
          ></v-select>
        </div>
        <div class="dropdown-item">
          <p class="dropdown-item-label">Status</p>
          <v-select placeholder="All statuses" :options="['OK', 'Warning', 'Error', 'Fatal']"></v-select>
        </div>
        <div class="dropdown-item">
          <p class="dropdown-item-label">Stage</p>
          <v-select placeholder="All stages" :options="['Live', 'Complete', 'Testing', 'Paused', 'Unknown']"></v-select>
        </div>
      </div>
    </div> -->

    <NameSearch :on-search-function="videoStore.getVideos"
                :on-clear-function="videoStore.getVideos" />
  </div>
</template>
