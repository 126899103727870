<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useApplicationStore } from '@stores/application';

import * as client from '@gabrielcam/api-client';

import Footer from '@components/layout/Footer.vue';
import Navbar from '@components/layout/Navbar.vue';
import Loading from '@components/Loading.vue';
import MainContentLayout from '@layouts/MainContentLayout.vue';
import SidebarLayout from '@layouts/SidebarLayout.vue';
import { PageNames } from '@viewModels/enums';
import PublicViewerHeader from '@components/layout/PublicViewerHeader.vue';
import PublicViewerLayout from '@layouts/PublicViewerLayout.vue';

interface ThemeSettings {
  logoBase64?: string;
  primaryColour?: string;
  secondaryColour?: string;
}

const applicationStore = useApplicationStore();
const { activeOrganisation } = storeToRefs(applicationStore);

const route = useRoute();
const pageName = computed(() => route.name as string);
const viewDetails = ref<client.View | null>(null);
const clientTheme = ref<ThemeSettings | null>(null);
const organisationTheme = ref<ThemeSettings | null>(null);

// Check if the client primaryColour and secondaryColour data is ready
const isClientThemeReady = computed(() => {
  return (
    clientTheme.value?.primaryColour && clientTheme.value?.secondaryColour
  );
});

// Check if the organisation primaryColour and secondaryColour data is ready
const isOrgThemeReady = computed(() => {
  return (
    organisationTheme.value?.primaryColour && organisationTheme.value?.secondaryColour
  );
});

/**
 * Fetches theme details for the client and organisation.
 *
 * If the current route is 'PageNames.ViewPublic', it fetches the view details
 * and updates the client theme settings. Otherwise, it only fetches the
 * organisation theme settings using the activeOrganisation from the
 * applicationStore.
 *
 * @returns {Promise<void>} A promise that resolves when the theme details are
 * fetched.
 */
async function fetchThemeDetails(): Promise<void> {
  try {
    // Check if the current route is for the  public viewer
    if (route.name === PageNames.ViewPublic) {
      // Get the viewId
      const viewId = route.params['id'] as string;

      if (viewId) {
        const details = await client.getViewById({
          viewId,
          includes: [client.Resources_Client.CLIENT],
        });

        // Store the view details
        viewDetails.value = details as client.View;

        // Update the Client Theme settings
        clientTheme.value = details.includes?.client?.settings ?? {};
      } else {
        console.warn('No valid viewId found in route params.');
      }
    }

    // Always update the Organisation Theme settings, regardless of route
    organisationTheme.value = activeOrganisation.value?.settings ?? {};

  } catch (error) {
    console.error('Error fetching themes:', error);
  }
}

// Pages that use the Client theme, add more as needed
const clientThemePages = [
  PageNames.ViewPublic,
];

onMounted(() => {
  fetchThemeDetails();
});
</script>

<template>
  <!-- Render the Client Theme -->
  <template v-if="clientThemePages.includes(pageName as PageNames)">
    <Loading v-if="!isClientThemeReady" :is-absolute-positioned="true" />

    <div v-if="isClientThemeReady"
         id="appContainer"
         :style="{
           '--primary-color': clientTheme?.primaryColour,
           '--secondary-color': clientTheme?.secondaryColour,
           '--logo': `url(${clientTheme?.logoBase64})`,
         }"
         class="app-container">
      <PublicViewerHeader />

      <PublicViewerLayout>
        <template #router-view>
          <router-view />
        </template>
      </PublicViewerLayout>
    </div>
  </template>

  <!-- All other pages with Organisation Theme -->
  <template v-else>
    <Loading v-if="!isOrgThemeReady" :is-absolute-positioned="true" />

    <div v-if="isOrgThemeReady"
         id="appContainer"
         :style="{
           '--primary-color': organisationTheme?.primaryColour,
           '--secondary-color': organisationTheme?.secondaryColour,
           '--logo': `url(${organisationTheme?.logoBase64})`,
         }"
         class="app-container">
      <SidebarLayout :page-name="pageName">
        <template #navbar>
          <Navbar />
        </template>
      </SidebarLayout>

      <MainContentLayout :page-name="pageName">
        <template #router-view>
          <router-view />
        </template>

        <template #footer>
          <Footer />
        </template>
      </MainContentLayout>
    </div>
  </template>
</template>

<style lang="scss" scoped>
.app-container {
  display: flex;
  flex-direction: column;
}
</style>
