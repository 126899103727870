<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useForm } from 'vee-validate';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { extractErrorMessage } from '@utils/errorUtils';
import { storeToRefs } from 'pinia';
import { BreadcrumbPaths, BreadcrumbTitles, ButtonType, ButtonVariant } from '@viewModels/enums';

import ButtonActions from '@layouts/ButtonActions.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import CameraEditForm from '@components/camera/CameraEditForm.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import SubHeader from '@components/SubHeader.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import Forbidden from '@layouts/Forbidden.vue';


const applicationStore = useApplicationStore();
const { adminMode } = storeToRefs(applicationStore);

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.Cameras, to: BreadcrumbPaths.Cameras },
  { title: BreadcrumbTitles.UnregisteredCameras, to: BreadcrumbPaths.UnregisteredCameras },
  { title: BreadcrumbTitles.EditCamera, active: true },
];

const route = useRoute();
const isSubmitting = ref<boolean>(false);
const cameraId = ref(route.params['id'] as string);
const currentCamera = ref<client.Camera>();
const isLoading = ref<boolean>(true);

// Form fields for CameraEditForm
// use vee-validate to validate the form
const { handleSubmit } = useForm();
const serialNumber = ref('');
const wpImei = ref('');
const wpSerialNumber = ref('');

// Handle the update event from CameraEditForm
const handleFormUpdate = (values: {
  serialNumber: string;
  wpImei: string;
  wpSerialNumber: string
}): void => {
  serialNumber.value = values.serialNumber;
  wpImei.value = values.wpImei;
  wpSerialNumber.value = values.wpSerialNumber;
};

/**
 * Handles the submission of the camera edit form.
 *
 * @function handleSubmit - Vee-Validate form submit handler
 * @async
 * @returns {Promise<void>}
 */
const handleSubmitForm = handleSubmit(async (): Promise<void> => {
  isSubmitting.value = true;

  // only allow adminMode users to update cameras
  if (!adminMode.value) {
    return;
  }

  const updatedCameraRequest = {
    serialNumber: serialNumber.value,
    meta: {
      wpImei: wpImei.value,
      wpSerialNumber: wpSerialNumber.value,
    },
  };

  try {
    currentCamera.value = await client.updateCameraById({
      cameraId: cameraId.value,
      requestBody: updatedCameraRequest,
    }) as client.Camera;

    applicationStore.publishSuccessNotification({
      text: 'Camera updated successfully.',
      autoCloseMs: 3000,
    });
  } catch (error) {
    applicationStore.publishErrorNotification({
      text: extractErrorMessage(error),
    });
  } finally {
    isSubmitting.value = false;
  }
});

// Get the current camera
onMounted(async () => {
  isLoading.value = true;
  try {
    currentCamera.value = await client.getCameraById({ cameraId: cameraId.value });
  } finally {
    isLoading.value = false;
  }
});
</script>

<template>
  <template v-if="adminMode">
    <SubHeader heading="Edit Camera"
               level="2" />

    <Breadcrumb :is-sticky="true"
                :items="breadcrumbs" />

    <ContainerCard>
      <Loading v-if="isLoading" />

      <section v-else>
        <form @submit="handleSubmitForm">
          <CameraEditForm v-if="currentCamera" :camera="currentCamera" @update="handleFormUpdate" />

          <ButtonActions>
            <ButtonComponent :variant="ButtonVariant.Dark"
                             :loading="isSubmitting"
                             :is-block-btn="true"
                             :type="ButtonType.Submit">
              Update
            </ButtonComponent>
          </ButtonActions>
        </form>
      </section>
    </ContainerCard>
  </template>

  <template v-else>
    <ContainerCard>
      <Forbidden heading-text="Unauthorised Access">
        <p>You do not have permission to access this page</p>
      </Forbidden>
    </ContainerCard>
  </template>
</template>
