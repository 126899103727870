<script lang="ts" setup>
import { computed } from 'vue';

import { ProgressBarSize, ProgressBarVariant } from '@viewModels/enums';

// Interface
interface ProgressBarProps {
  percent: number;
  hasBottomMargin?: boolean;
  hasText?: boolean;
  variant?: ProgressBarVariant;
  height?: ProgressBarSize.Small | ProgressBarSize.Medium | ProgressBarSize.Large;
}

// Props to Interface
const props = defineProps<ProgressBarProps>();

// Default values
const variant = computed(() => props.variant || ProgressBarVariant.Success);
const hasText = computed(() => props.hasText || false);
const height = computed(() => props.height || ProgressBarSize.Medium);
const hasBottomMargin = computed(() => props.hasBottomMargin || false);

// Conditional classes
const progressBarClasses = computed(() => {
  return {
    'margin-bottom': hasBottomMargin.value || false,
    'progress-bar--small': height.value === ProgressBarSize.Small,
    'progress-bar--medium': height.value === ProgressBarSize.Medium,
    'progress-bar--large': height.value === ProgressBarSize.Large,
  };
});
</script>

<template>
  <div :aria-valuenow="percent"
       aria-label="Progress bar"
       aria-valuemax="100"
       aria-valuemin="0"
       class="progress-bar"
       :class="progressBarClasses"
       role="progressbar">
    <!-- Layout for when percent is 0 -->
    <div v-if="percent === 0"
         class="progress-bar__limit"
         :style="{ width: '100%' }">
      <span class="progress-bar__status">0%</span>
    </div>

    <!-- Layout for all other percentages -->
    <div v-else
         class="progress-bar__limit"
         :class="`progress-bar__limit--${variant}`"
         :style="{ width: percent + '%' }">
      <span v-if="hasText">{{ percent }}%</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.progress-bar {
  position: relative;
  width: 100%;
  height: 1.2rem;
  background-color: $neutral-200;
  border-radius: 0.375rem;

  &--small {
    height: 0.5rem;
  }

  &--medium {
    height: 1.2rem;
  }

  &--large {
    height: 1.8rem;
  }

  &.margin-bottom {
    margin-bottom: $margin-bottom;
  }

  &__limit {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 0.675em;
    line-height: 1;
    color: $neutral-800;
    border-radius: 0.375rem;
    transition: width 1500ms ease-in-out;
  }

  /* Define colors for each variant */
  &__limit--primary {
    color: $purple-100;
    background-color: $purple-800;
  }

  &__limit--secondary {
    color: $gold-100;
    background-color: $gold-800;
  }

  &__limit--success {
    color: $green-100;
    background-color: $green-800;
  }

  &__limit--danger {
    color: $red-100;
    background-color: $red-900;
  }

  &__limit--warning {
    color: $orange-100;
    background-color: $orange-800;
  }

  &__limit--info {
    color: $blue-100;
    background-color: $blue-800;
  }

  &__limit--light {
    color: $neutral-800;
    background-color: $neutral-400;
  }

  &__limit--dark {
    color: $neutral-100;
    background-color: $neutral-800;
  }

  &__status {
    text-align: center;
  }
}
</style>
