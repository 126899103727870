<template>
  <div class="button-actions button-actions--form-footer">
    <slot />
  </div>
</template>

<style scoped lang="scss">
@use '@scss/variables' as *;

.button-actions {
  display: flex;
  gap: 20px;

  &--form-footer {
    flex-direction: column-reverse;

    @media (min-width: $breakpoint-lg) {
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}
</style>
