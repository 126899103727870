import {
  type Client,
  type Organisation,
  type Project,
  Resources
} from '@gabrielcam/api-client';

function isObject(x: unknown): x is Record<string, unknown> {
  return typeof x === 'object' && !Array.isArray(x) && x !== null;
} 

export function isOrganisationResource(x: unknown): x is Organisation {
  return isObject(x) && x['object'] === Resources.ORGANISATION;
}

export function isClientResource(x: unknown): x is Client {
  return isObject(x) && x['object'] ===  Resources.CLIENT;
}

export function isProjectResource(x: unknown): x is Project {
  return isObject(x) && x['object'] ===  Resources.PROJECT;
}
