<script lang="ts" setup>
import type { Placement } from 'floating-vue';
import 'floating-vue/dist/style.css';

import { useMobileTooltip } from '@utils/useMobileTooltip';

import { ButtonVariant } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';

import ButtonComponent from '@components/ButtonComponent.vue';

const { isMobile, onShow, onHide } = useMobileTooltip();

interface TooltipProps {
  placement?: Placement;
  content?: string;
}

const { placement = 'bottom', content = '' } = defineProps<TooltipProps>();
</script>

<template>
  <VDropdown :placement="placement"
             :positioning-disabled="isMobile"
             @apply-show="isMobile && onShow()"
             @apply-hide="isMobile && onHide()">
    <ButtonComponent :variant="ButtonVariant.Transparent"
                     :icon-name="IconName.QuestionMarkCircleIcon"
                     :icon-style="IconStyle.Outline"
                     :is-icon-btn="true" />

    <template #popper="{ hide }">
      <div :class="{ 'v-popper__content': isMobile }">
        <template v-if="content">
          {{ content }}
        </template>
        <slot v-else />
      </div>

      <ButtonComponent v-if="isMobile"
                       :variant="ButtonVariant.Dark"
                       :is-block-btn="true"
                       @click="hide()">
        Close
      </ButtonComponent>
    </template>
  </VDropdown>
</template>

<style lang="scss">
@use '@scss/variables' as *;

// https://floating-vue.starpad.dev/
.v-popper--theme-dropdown .v-popper__inner {
  padding: 15px;
}

.v-popper__popper .v-popper__wrapper {
  @media screen and (width >= $breakpoint-lg) {
    max-width: 600px;
  }
}

.v-popper__popper--no-positioning {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;

  &.v-popper__popper--hidden .v-popper__wrapper {
    transform: translateY(100%);
  }

  .v-popper--theme-dropdown .v-popper__inner {
    padding: 15px;
  }

  .v-popper__popper .v-popper__wrapper {
    max-width: 600px;
  }

  .v-popper__popper--no-positioning {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    pointer-events: auto;
    transition: transform 0.15s ease-out;

    & .v-popper__inner {
      border-radius: 15px;

      @media screen and (width <= $breakpoint-lg) {
        border-radius: 15px 15px 0 0;
      }
    }
  }

  &.v-popper__popper--hidden .v-popper__wrapper {
    transform: translateY(100%);
  }

  & .v-popper__backdrop {
    display: block;
    background: rgba(0 0 0 / 90%);
  }

  & .v-popper__wrapper {
    width: 100%;
    pointer-events: auto;
    transition: transform 0.15s ease-out;
  }

  & .v-popper__content {
    @media (width <= $breakpoint-lg) {
      margin-bottom: 15px;
    }
  }
}
</style>
