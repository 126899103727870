<script setup lang="ts">
import { PropType, ref, watch } from 'vue';
import { useField } from 'vee-validate';
import * as yup from 'yup';
import * as client from '@gabrielcam/api-client';
import Heading from '@components/Heading.vue';

const props = defineProps({
  camera: {
    type: Object as PropType<client.Camera>,
    required: true,
  },
});

// Emit the updated form values for onSubmit
const emit = defineEmits<{
  (e: 'update', values: { serialNumber: string; wpImei: string; wpSerialNumber: string }): void;
}>();

// Define validation regex for alphanumeric and dash characters
const alphanumericDashRegex = /^[a-zA-Z0-9-]*$/;

// Set up read-only fields without validation
const status = ref(props.camera.status);
const systemType = ref(props.camera.systemType);

// Set up fields with validation rules for editable fields
const { value: serialNumber, errorMessage: serialNumberError } = useField<string>(
  'serialNumber',
  yup
    .string()
    .matches(alphanumericDashRegex, 'Serial number must only contain letters, numbers and dashes')
    .optional()
);

const { value: wpImei, errorMessage: wpImeiError } = useField<string>(
  'wpImei',
  yup
    .string()
    .matches(alphanumericDashRegex, 'Modem IMEI must only contain letters, numbers and dashes')
    .optional()
);

const { value: wpSerialNumber, errorMessage: wpSerialNumberError } = useField<string>(
  'wpSerialNumber',
  yup
    .string()
    .matches(alphanumericDashRegex, 'Modem Serial Number must only contain letters, numbers and dashes')
    .optional()
);

// Initialise values for editable fields
serialNumber.value = props.camera.serialNumber ?? '';
wpImei.value = (props.camera.meta?.['wpImei'] as string) ?? '';
wpSerialNumber.value = (props.camera.meta?.['wpSerialNumber'] as string) ?? '';

// Watch for changes and emit updated values to parent
watch([serialNumber, wpImei, wpSerialNumber], () => {
  emit('update', {
    serialNumber: serialNumber.value,
    wpImei: wpImei.value,
    wpSerialNumber: wpSerialNumber.value,
  });
});
</script>

<template>
  <div class="field-group">
    <div class="field-group-info">
      <Heading level="3">
        Camera Device Information
      </Heading>
      <p>Edit camera device information.</p>
    </div>
    <div class="fields">
      <div class="row-half">
        <div class="field">
          <label for="status">Status:</label>
          <input id="status"
                 v-model="status"
                 disabled
                 type="text">
        </div>
        <div class="field">
          <label for="systemType">System Type:</label>
          <input id="systemType"
                 v-model="systemType"
                 disabled
                 type="text">
        </div>
      </div>

      <div class="row-half">
        <div class="field">
          <label for="serialNumber">GabrielCam Serial Number:</label>
          <input id="serialNumber" v-model="serialNumber" type="text">
          <p v-if="serialNumberError" class="message-error message">
            {{ serialNumberError }}
          </p>
        </div>
      </div>

      <div class="row-half">
        <div class="field">
          <label for="wpSerialNumber">Modem Serial Number:</label>
          <input id="wpSerialNumber" v-model="wpSerialNumber" type="text">
          <p v-if="wpSerialNumberError" class="message-error message">
            {{ wpSerialNumberError }}
          </p>
        </div>
        <div class="field">
          <label for="wpImei">Modem IMEI:</label>
          <input id="wpImei" v-model="wpImei" type="text">
          <p v-if="wpImeiError" class="message-error message">
            {{ wpImeiError }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
