<script setup lang="ts">
import { onMounted, ref } from 'vue';

import * as Ably from 'ably';

import * as client from '@gabrielcam/api-client';

import ContainerCard from '@components/cards/ContainerCard.vue';
import SubHeader from '@components/SubHeader.vue';

const textarea = ref<HTMLTextAreaElement | null>(null);

async function connect(): Promise<void> {
  const ablyToken = await client.getAblyToken();

  const ably = new Ably.Realtime({
    token: ablyToken,
    authCallback: async () => {
      return await client.getAblyToken();
    },
  });
  const channel = ably.channels.get('feed');

  ably.connection.on('connected', function () {
    textarea.value!.value += 'connected!\n';
  });

  ably.connection.on('failed', function () {
    textarea.value!.value += 'Connection failed\n';
  });

  channel.subscribe(function (message) {
    textarea.value!.value += JSON.stringify(message) + '\n';
  });
}

onMounted(() => {
  textarea.value!.value += 'Connecting... ';
  connect();
});
</script>

<template>
  <SubHeader heading="Event Steam"
             level="2" />

  <ContainerCard>
    <textarea id="message"
              ref="textarea"
              rows="5"
              cols="30" />
  </ContainerCard>
</template>

<style lang="scss" scoped>
#message {
  width: 100%;
  height: 300px;
}
</style>
