export interface DateRange {
  start: Date;
  end: Date;
}
export function getDatesOutSideRange(dateList: string[]): DateRange[] {
  const dateRanges: DateRange[] = [];

  if (dateList.length === 0) {
    return dateRanges;
  }

  const sortedDates: Date[] = dateList
    .map((dateString) => new Date(dateString + 'T00:00:00Z'))
    .filter((date) => !isNaN(date.getTime()))
    .sort((a, b) => {
      return a.getTime() - b.getTime();
    });
  const maxDate = new Date('9999-12-31T23:59:59.999Z');
  const minDate = new Date('0000-01-01T00:00:00.000Z');
  const firstDate = sortedDates[0]!;
  const lastDate = sortedDates.at(-1)!;

  const pastDisabledEndDay = new Date(firstDate);
  pastDisabledEndDay.setDate(pastDisabledEndDay.getDate() - 1);
  dateRanges.push({ start: minDate, end: pastDisabledEndDay });

  const futureDisabledStartDay = new Date(lastDate);
  futureDisabledStartDay.setDate(futureDisabledStartDay.getUTCDate() + 1);
  dateRanges.push({ start: futureDisabledStartDay, end: maxDate });
  return dateRanges;
}

export function generateMissingDateRanges(dateList: string[]): DateRange[] {
  const dateRanges: DateRange[] = [];

  if (dateList.length === 0) {
    return dateRanges;
  }

  const sortedDates: Date[] = dateList
    .map((dateString) => new Date(dateString + 'T00:00:00Z'))
    .filter((date) => !isNaN(date.getTime()))
    .sort((a, b) => {
      return a.getTime() - b.getTime();
    });
  const maxDate = new Date('9999-12-31T23:59:59.999Z');
  const minDate = new Date('0000-01-01T00:00:00.000Z');
  const firstDate = sortedDates[0]!;
  const lastDate = sortedDates.at(-1)!;

  const pastDisabledEndDay = new Date(firstDate);
  pastDisabledEndDay.setDate(pastDisabledEndDay.getDate() - 1);
  dateRanges.push({ start: minDate, end: pastDisabledEndDay });

  const futureDisabledStartDay = new Date(lastDate);
  futureDisabledStartDay.setDate(futureDisabledStartDay.getUTCDate() + 1);
  dateRanges.push({ start: futureDisabledStartDay, end: maxDate });

  for (let index = 0; index < sortedDates.length - 1; index++) {
    const currentDate = sortedDates[index]!;
    const nextDate = sortedDates[index + 1]!;

    const diffTime = Math.abs(currentDate.getTime() - nextDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));

    if (diffDays > 1) {
      let startDate = new Date(currentDate);
      startDate.setUTCDate(startDate.getUTCDate() + 1);
      let endDate = new Date(nextDate);
      endDate.setUTCDate(endDate.getUTCDate() - 1);
      dateRanges.push({ start: startDate, end: endDate });
    }
  }
  return dateRanges;
}
