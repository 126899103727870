<script setup lang="ts">
import { ref, watch } from 'vue';

import dayjs from 'dayjs';
import 'dayjs/locale/en';
import { DatePicker } from 'v-calendar';

const calendar = ref();

const props = defineProps<{
  availableDates: string[];
  modelValue: Date;
}>();

watch(
  () => props.availableDates,
  async () => {
    if (calendar.value == undefined) return;
    const dayToMoveTo = props.availableDates[props.availableDates.length - 1];
    if (dayToMoveTo == undefined) return;
    selectedDate.value = new Date(dayToMoveTo);
    await calendar.value.move(dayToMoveTo); //Got to do this because the picker nav gets disabled
  }
);
const selectedDate = ref<Date>(props.modelValue);

const emit = defineEmits<{
  (e: 'dateSelected'): void;
  (e: 'update:modelValue', value: Date): void;
}>();

function onUpdateDate(e: string | number | Date | dayjs.Dayjs | null | undefined): void {
  if (e) {
    emit('update:modelValue', dayjs(e).startOf('day').toDate());
    emit('dateSelected');
  }
}
</script>

<template>
  <DatePicker ref="calendar"
              v-model="selectedDate"
              expanded
              mode="date"
              :min-date="props.availableDates[0]"
              :max-date="props.availableDates.at(-1)"
              @update:model-value="onUpdateDate" />
</template>
