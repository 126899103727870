import dayjs from 'dayjs';

import { ScheduleViewModel } from '@viewModels/scheduleViewModel';

export class ScheduleWp {
  // scheduleMonth: number = 0;
  scheduleStartTime = 0;
  scheduleEndTime = 0;
  scheduleRate = 0;
  scheduleDays = 0;
}
function convertLocalToEpoch(localTimeString: string) {
  const hour = localTimeString.split(':')[0];
  const localTime = dayjs().hour(Number(hour));
  const utcTime = localTime.utc();
  return utcTime.unix();
}

export function convertToScheduleDto(cameraSchedule: ScheduleViewModel): ScheduleWp {
  const scheduleDto: ScheduleWp = {
    // schedule_type: type, // Set the appropriate value here
    // scheduleMonth: 0,//cameraSchedule.month.toUpperCase(),
    scheduleRate: cameraSchedule.captureRate,
    scheduleDays: 0, // We will update this based on weekdays later
    scheduleStartTime: 0, // We will update this based on startHour later
    scheduleEndTime: 0, // We will update this based on endHour later
    // rapid_capture_enabled: cameraSchedule.rapidCaptureEnabled,

    // rapid_start_time_utc: '1970-01-01T00:00', // We will update this based on startHour later
    // rapid_end_time_utc: '1970-01-01T00:00', // We will update this based on endHour later
    // rapid_capture_rate: cameraSchedule.rapidCaptureRate,
  };

  // Calculate the days_of_week value based on weekdays
  if (cameraSchedule.monday) scheduleDto.scheduleDays |= 1;
  if (cameraSchedule.tuesday) scheduleDto.scheduleDays |= 2;
  if (cameraSchedule.wednesday) scheduleDto.scheduleDays |= 4;
  if (cameraSchedule.thursday) scheduleDto.scheduleDays |= 8;
  if (cameraSchedule.friday) scheduleDto.scheduleDays |= 16;
  if (cameraSchedule.saturday) scheduleDto.scheduleDays |= 32;
  if (cameraSchedule.sunday) scheduleDto.scheduleDays |= 64;

  scheduleDto.scheduleStartTime = convertLocalToEpoch(cameraSchedule.startHour);
  scheduleDto.scheduleEndTime = convertLocalToEpoch(cameraSchedule.endHour);

  // scheduleDto.rapid_start_time_utc = convertLocalToDtoTime(cameraSchedule.rapidStartHour);
  // scheduleDto.rapid_end_time_utc = convertLocalToDtoTime(cameraSchedule.rapidEndHour);

  return scheduleDto;
}

function convertEpochToLocal(epochTime: number): string {
  const localTime = dayjs.unix(epochTime).local();
  return `${padZero(localTime.hour())}:00`;
}

function padZero(num: number): string {
  return num.toString().padStart(2, '0');
}

export function convertToScheduleViewModel(scheduleDto: ScheduleWp): ScheduleViewModel {
  const scheduleViewModel = new ScheduleViewModel();

  // Decode scheduleDays into individual weekdays
  scheduleViewModel.monday = (scheduleDto.scheduleDays & 1) !== 0;
  scheduleViewModel.tuesday = (scheduleDto.scheduleDays & 2) !== 0;
  scheduleViewModel.wednesday = (scheduleDto.scheduleDays & 4) !== 0;
  scheduleViewModel.thursday = (scheduleDto.scheduleDays & 8) !== 0;
  scheduleViewModel.friday = (scheduleDto.scheduleDays & 16) !== 0;
  scheduleViewModel.saturday = (scheduleDto.scheduleDays & 32) !== 0;
  scheduleViewModel.sunday = (scheduleDto.scheduleDays & 64) !== 0;

  // Convert epoch times back to local times
  scheduleViewModel.startHour = convertEpochToLocal(scheduleDto.scheduleStartTime);
  scheduleViewModel.endHour = convertEpochToLocal(scheduleDto.scheduleEndTime);

  // Set the capture rate
  scheduleViewModel.captureRate = scheduleDto.scheduleRate;

  // Set other properties if needed

  return scheduleViewModel;
}
