<script lang="ts" setup>
import { PropType } from 'vue';

import { ScheduleViewModel } from '@viewModels/scheduleViewModel';

import Heading from '@components/Heading.vue';

const props = defineProps({
  heaterSchedule: {
    type: Object as PropType<ScheduleViewModel>,
    required: true,
  },
});

const info = props.heaterSchedule;
info.captureRate = 0;
const hourList: string[] = [];
for (let index = 0; index < 24; index++) {
  hourList.push(`${index.toString().padStart(1, '0')}:00`);
}
</script>

<template>
  <Heading :has-bottom-margin="true"
           level="2">
    Current Settings
  </Heading>
  <div class="fields">
    <div class="row-third">
      <div class="field">
        <label for="captureStartTime">Start Hour</label>
        <v-select v-model="info.startHour"
                  :clearable="false"
                  :options="hourList" />
      </div>
      <div class="field">
        <label for="captureEndTime">End Hour</label>
        <v-select v-model="info.endHour"
                  :clearable="false"
                  :options="hourList" />
      </div>
    </div>

    <div class="days">
      <div class="checkbox-field">
        <label for="MondayHeater">Monday</label>
        <input id="MondayHeater"
               v-model="info.monday"
               type="checkbox">
      </div>
      <div class="checkbox-field">
        <label for="TuesdayHeater">Tuesday</label>
        <input id="TuesdayHeater"
               v-model="info.tuesday"
               type="checkbox">
      </div>
      <div class="checkbox-field">
        <label for="WednesdayHeater">Wednesday</label>
        <input id="WednesdayHeater"
               v-model="info.wednesday"
               type="checkbox">
      </div>
      <div class="checkbox-field">
        <label for="ThursdayHeater">Thursday</label>
        <input id="ThursdayHeater"
               v-model="info.thursday"
               type="checkbox">
      </div>
      <div class="checkbox-field">
        <label for="FridayHeater">Friday</label>
        <input id="FridayHeater"
               v-model="info.friday"
               type="checkbox">
      </div>
      <div class="checkbox-field">
        <label for="SaturdayHeater">Saturday</label>
        <input id="SaturdayHeater"
               v-model="info.saturday"
               type="checkbox">
      </div>
      <div class="checkbox-field">
        <label for="SundayHeater">Sunday</label>
        <input id="SundayHeater"
               v-model="info.sunday"
               type="checkbox">
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.fields {
  margin-bottom: 24px;
}
</style>
