<script setup lang="ts">
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import * as client from '@gabrielcam/api-client';

import { BreadcrumbTitles, ButtonSize, ButtonVariant, PageNames } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';

import EmptyState from '@layouts/EmptyState.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import { DirectoryTableColumn } from '@components/directory/models/DirectoryTableModels';
import DirectoryTable from '@components/directory/DirectoryTable.vue';
import Loading from '@components/Loading.vue';
import SubHeader from '@components/SubHeader.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';

enum TableHeaders {
  Name = 'Name',
  Actions = 'Actions',
}

const router = useRouter();

async function getOrganisationList(): Promise<client.Organisation[]> {
  return (await client.listOrganisations()).data;
}

const columns: DirectoryTableColumn[] = [
  {
    label: TableHeaders.Name,
    field: 'name',
    headerClasses: 'text--white-space-nowrap',
    width: '99%',
  },
  {
    label: TableHeaders.Actions,
    field: 'id',
    headerClasses: '',
    width: '1%',
  },
];

const table = reactive({
  columns: columns,
  totalRecordCount: 0,
  sortable: {
    order: 'id',
    sort: 'asc',
  },
});

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllOrganisations, active: true },
];
</script>

<template>
  <SubHeader heading="Organisations"
             level="2" />

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <div class="main-wrapper">
        <DirectoryTable :retrieve-data="getOrganisationList"
                  :columns="table.columns"
                  :total="table.totalRecordCount"
                  :sortable="table.sortable">
          <template #table-empty>
            <EmptyState heading-text="No organisations found"
                        :icon-name="IconName.UserGroupIcon"
                        :icon-style="IconStyle.Outline" />
          </template>

          <template #cell="{ row, column }">
            <div v-if="column.label === TableHeaders.Name"
                 class="text--truncate">
              {{ row.name }}
            </div>

            <div v-if="column.label === TableHeaders.Actions"
                 class="d-flex gap-20">
              <ButtonComponent :icon-name="IconName.WrenchIcon"
                               :icon-style="IconStyle.Outline"
                               :size="ButtonSize.Small"
                               :variant="ButtonVariant.Dark"
                               @click="() => router.push({ name: PageNames.OrganisationSettings, params: { id: row.id } })">
                Edit
              </ButtonComponent>
            </div>
          </template>
        </DirectoryTable>
      </div>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>
</template>
