export class ScheduleViewModel {
  month = '';
  monday = true;
  tuesday = true;
  wednesday = true;
  thursday = true;
  friday = true;
  saturday = true;
  sunday = true;

  startHour = '09:00';
  endHour = '17:00';
  captureRate = 5;

  rapidCaptureEnabled = false;
  rapidStartHour = '09:00';
  rapidEndHour = '17:00';
  rapidCaptureRate = 5;

  getNextEnabledDay(): string | null {
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const today = new Date().getDay();

    for (let i = 0; i < dayNames.length; i++) {
      const nextDayIndex = (today + i) % 7;
      const nextDayName: string = dayNames[nextDayIndex] ?? '';

      if (this[nextDayName.toLowerCase() as keyof ScheduleViewModel] === true) {
        return nextDayName;
      }
    }
    return null; // No enabled days found in the week
  }
}
