<script setup lang="ts">
import { Ref, ref } from 'vue';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import { ButtonType, ButtonVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import ButtonActions from '@layouts/ButtonActions.vue';

const props = defineProps<{
  resetPassword: Function;
}>();

const emit = defineEmits<{
  (e: 'onSuccess'): void;
}>();

const isSubmitting: Ref<boolean> = ref(false);

interface ResetPasswordForm {
  password: string;
  confirmPassword: string;
}

const schema = yup.object({
  password: yup.string().required().min(6).max(20),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Password must match')
    .required(),
});

const { handleSubmit } = useForm<ResetPasswordForm>({
  validationSchema: schema,
});

const { value: passwordValue, errorMessage: passwordError } = useField<string>('password', 'password', {
  modelPropName: 'password',
});
const { value: confirmPasswordValue, errorMessage: confirmPasswordError } = useField<string>(
  'confirmPassword',
  'confirmPassword',
  {
    modelPropName: 'confirmPassword',
  }
);

const onSubmit = handleSubmit(async (values, actions) => {
  isSubmitting.value = true;
  try {
    await props.resetPassword(values.password);
    emit('onSuccess');
  } catch (error: any) {
    actions.setErrors({ password: 'Error occurred' });
    isSubmitting.value = false;
    console.error(error);
    return;
  }
});
</script>

<template>
  <form @submit="onSubmit">
    <section class="account-form--content">
      <div class="fields">
        <div class="field">
          <label for="password">Password</label>
          <input id="password"
                 v-model="passwordValue"
                 type="password"
                 autocomplete="new-password">
          <p class="message message-error">
            {{ passwordError }}
          </p>
        </div>
        <div class="field">
          <label for="password-confirm">Confirm password</label>
          <input id="password-confirm"
                 v-model="confirmPasswordValue"
                 type="password"
                 autocomplete="new-password">
          <p class="message message-error">
            {{ confirmPasswordError }}
          </p>
        </div>
      </div>
    </section>

    <ButtonActions>
      <ButtonComponent :disabled="isSubmitting"
                       :loading="isSubmitting"
                       :type="ButtonType.Submit"
                       :variant="ButtonVariant.Dark"
                       :is-block-btn="true">
        Set Password
      </ButtonComponent>
    </ButtonActions>
  </form>
</template>
