<script lang="ts" setup>
import { ref } from 'vue';

import { ScheduleViewModel } from '@viewModels/scheduleViewModel';

import Heading from '@components/Heading.vue';

const info = defineModel<ScheduleViewModel>('cameraSchedule', { default: () => {} });

console.log(info.value);

// const info = props.cameraSchedule;
const captureRateMinsList: number[] = [1, 5, 10, 15, 20, 25, 30, 60];
const rapidCaptureRateSecondsList: number[] = [15, 20, 25, 30, 60, 120, 180];

const maximumRapidTimeHours = 2;
const rapidStartHourList = ref<string[]>();
const rapidEndHourList = ref<string[]>();
const hasRapidCapture = ref<boolean>(false);

const hourList: string[] = [];
for (let index = 0; index < 24; index++) {
  hourList.push(`${index.toString().padStart(2, '0')}:00`);
}

// Initialize startHour and endHour with a default value if undefined
// if (info.startHour === undefined) {
//   info.startHour = 0;
// }
//
// if (info.endHour === undefined) {
//   info.endHour = 23;
// }

function generateRapidStartHours(startHour: string, endHour: string): string[] {
  const startHour24h = parseInt(startHour.split(':').at(0) ?? '0');
  const endHour24h = parseInt(endHour.split(':').at(0) ?? '0');

  const rapidTimes: string[] = [];

  if (startHour24h <= endHour24h) {
    for (let i = startHour24h; i < endHour24h; i++) {
      const hour = i < 10 ? `0${i}` : `${i}`;
      rapidTimes.push(`${hour}:00`);
    }
    return rapidTimes;
  }

  for (let i = startHour24h; i <= 23; i++) {
    const hour = i < 10 ? `0${i}` : `${i}`;
    rapidTimes.push(`${hour}:00`);
  }

  for (let i = 0; i < endHour24h; i++) {
    const hour = i < 10 ? `0${i}` : `${i}`;
    rapidTimes.push(`${hour}:00`);
  }
  return rapidTimes;
}

function generateRapidEndHours(startHour: string, endHour: string, rapidDuration = 2): string[] {
  const startHour24h = parseInt(startHour.split(':').at(0) ?? '0');
  const endHour24h = parseInt(endHour.split(':').at(0) ?? '0');

  const rapidTimes: string[] = [];

  if (startHour24h <= endHour24h) {
    for (let i = startHour24h + 1; i <= endHour24h; i++) {
      const hour = i < 10 ? `0${i}` : `${i}`;
      rapidTimes.push(`${hour}:00`);
    }
    if (rapidTimes.length < rapidDuration) rapidDuration = rapidTimes.length;
    return rapidTimes.slice(0, rapidDuration);
  }

  for (let i = startHour24h + 1; i <= 23; i++) {
    const hour = i < 10 ? `0${i}` : `${i}`;
    rapidTimes.push(`${hour}:00`);
  }

  for (let i = 0; i <= endHour24h; i++) {
    const hour = i < 10 ? `0${i}` : `${i}`;
    rapidTimes.push(`${hour}:00`);
  }
  if (rapidTimes.length < rapidDuration) rapidDuration = rapidTimes.length;
  return rapidTimes.slice(0, rapidDuration);
}

function updateRapidHourLists(): void {
  rapidStartHourList.value = generateRapidStartHours(info.value.startHour, info.value.endHour);
  rapidEndHourList.value = generateRapidEndHours(info.value.rapidStartHour, info.value.endHour, maximumRapidTimeHours);
}

updateRapidHourLists();
</script>

<template>
  <Heading :has-bottom-margin="true"
           level="2">
    Current Settings
  </Heading>
  <div class="fields">
    <div class="row-third">
      <div class="field">
        <label for="captureStartTime">Start Hour</label>
        <v-select v-model="info.startHour"
                  :clearable="false"
                  :options="hourList"
                  @option:selected="updateRapidHourLists" />
      </div>
      <div class="field">
        <label for="captureEndTime">End Hour</label>
        <v-select v-model="info.endHour"
                  :clearable="false"
                  :options="hourList"
                  @option:selected="updateRapidHourLists" />
      </div>
      <div class="field">
        <label for="captureRate">Capture Rate (mins)</label>
        <v-select v-model="info.captureRate"
                  :clearable="false"
                  :options="captureRateMinsList" />
      </div>
    </div>
    <div v-if="hasRapidCapture"
         class="row">
      <div class="checkbox-field">
        <label for="rapidCaptureEnabled">Enable Rapid Capture</label>
        <input id="rapidCaptureEnabled"
               v-model="info.rapidCaptureEnabled"
               type="checkbox">
      </div>
    </div>
    <div v-if="info.rapidCaptureEnabled"
         class="row-third">
      <div class="field">
        <label for="rapidCaptureStartTime">Rapid Start Hour</label>
        <v-select v-model="info.rapidStartHour"
                  :clearable="false"
                  :options="rapidStartHourList"
                  @option:selected="updateRapidHourLists" />
      </div>
      <div class="field">
        <label for="rapidCaptureEndTime">Rapid End Hour</label>
        <v-select v-model="info.rapidEndHour"
                  :clearable="false"
                  :options="rapidEndHourList"
                  @option:selected="updateRapidHourLists" />
      </div>
      <div class="field">
        <label for="rapidCaptureRate">Capture Rate (secs)</label>
        <v-select v-model="info.rapidCaptureRate"
                  :clearable="false"
                  :options="rapidCaptureRateSecondsList" />
      </div>
    </div>

    <div class="days">
      <div class="checkbox-field">
        <label for="MondayCamera">Monday</label>
        <input id="MondayCamera"
               v-model="info.monday"
               type="checkbox">
      </div>
      <div class="checkbox-field">
        <label for="TuesdayCamera">Tuesday</label>
        <input id="TuesdayCamera"
               v-model="info.tuesday"
               type="checkbox">
      </div>
      <div class="checkbox-field">
        <label for="WednesdayCamera">Wednesday</label>
        <input id="WednesdayCamera"
               v-model="info.wednesday"
               type="checkbox">
      </div>
      <div class="checkbox-field">
        <label for="ThursdayCamera">Thursday</label>
        <input id="ThursdayCamera"
               v-model="info.thursday"
               type="checkbox">
      </div>
      <div class="checkbox-field">
        <label for="FridayCamera">Friday</label>
        <input id="FridayCamera"
               v-model="info.friday"
               type="checkbox">
      </div>
      <div class="checkbox-field">
        <label for="SaturdayCamera">Saturday</label>
        <input id="SaturdayCamera"
               v-model="info.saturday"
               type="checkbox">
      </div>
      <div class="checkbox-field">
        <label for="SundayCamera">Sunday</label>
        <input id="SundayCamera"
               v-model="info.sunday"
               type="checkbox">
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.fields {
  margin-bottom: 24px;
}
</style>
