<script setup lang="ts">
import { onMounted, ref } from 'vue';

import { Resolution } from '@viewModels/resolutionViewModel';

const emit = defineEmits<{
  (e: 'onSelected', resolution: Resolution): void;
}>();

const selectedResolution = ref<Resolution>();
const presets: Resolution[] = [
  {
    title: '4K - 3840x2160',
    width: 3840,
    height: 2160,
    bitrate: 30000,
  },
  {
    title: '2.5K - 2560x1440',
    width: 2560,
    height: 1440,
    bitrate: 18000,
  },
  {
    title: '2K - 2048x1152',
    width: 2048,
    height: 1152,
    bitrate: 18000,
  },
  {
    title: '1080p - 1920x1080',
    width: 1920,
    height: 1080,
    bitrate: 8000,
  },
  {
    title: '720p - 1280x720',
    width: 1280,
    height: 720,
    bitrate: 3000,
  },
];

function selected(item: Resolution): void {
  emit('onSelected', item);
}

onMounted(() => {
  const defaultResolution: Resolution | undefined = presets.at(3);
  selectedResolution.value = defaultResolution;
  if (defaultResolution === undefined) return;
  selected(defaultResolution);
});
</script>

<template>
  <v-select v-model="selectedResolution"
            label="title"
            :options="presets"
            :clearable="false"
            @option:selected="selected" />
</template>
