<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { PageNames } from '@viewModels/enums';

interface CardContainerProps {
  isGoogleMap?: boolean;
}

const route = useRoute();
const props = defineProps<CardContainerProps>();
const googleMapRoute = computed(() => route.name === PageNames.ViewMap);
const googleMapClass = computed(() =>
  googleMapRoute.value || props.isGoogleMap ? 'card-container__google-map' : '',
);
</script>

<template>
  <section class="card-container"
           :class="googleMapRoute ? googleMapClass : ''">
    <slot />
  </section>
</template>

<style scoped lang="scss">
@use '@/scss/variables' as *;

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: clamp($gap-desktop, 2vw, $gap-default * 2);
  justify-items: center;

  &__google-map {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    order: 2;

    @media screen and (width >= $breakpoint-xl) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      order: 1;
      margin-bottom: unset;
    }
  }
}


</style>
