<script setup lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';

import { BreadcrumbPaths, BreadcrumbTitles } from '@viewModels/enums';

import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import ProjectCreationForm from '@components/project/ProjectCreationForm.vue';
import SubHeader from '@components/SubHeader.vue';

const route = useRoute();
const clientId = ref<string>();

clientId.value = route.params['id'] as string | undefined;

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllProjects, to: BreadcrumbPaths.Projects },
  { title: BreadcrumbTitles.CreateProject, active: true },
];
</script>

<template>
  <SubHeader heading="Create Project"
             level="2" />

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <ProjectCreationForm :client-id="clientId" />
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>
</template>
