<script lang="ts" setup>
import { reactive, Ref, ref } from 'vue';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import {
  BadgeVariant,
  BreadcrumbTitles,
  ButtonSize,
  ButtonVariant,
  CameraStatus,
  NotificationCountVariant,
  PageNames,
} from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';
import BadgeComponent from '@components/BadgeComponent.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import { DirectoryTableColumn } from '@components/directory/models/DirectoryTableModels';
import DirectoryTable from '@components/directory/DirectoryTable.vue';
import Loading from '@components/Loading.vue';
import ModalComponent from '@components/ModalComponent.vue';
import SubHeader from '@components/SubHeader.vue';
import EmptyState from '@layouts/EmptyState.vue';
import { storeToRefs } from 'pinia';
import { extractErrorMessage } from '@utils/errorUtils';

enum TableHeaders {
  DeviceId = 'Device ID',
  SystemType = 'System Type',
  Status = 'Status',
  Actions = 'Actions',
}

const tableKey = ref(0);  // Reactive key for forcing table reloads because of <Suspense>

const showDeleteCameraModel = ref(false);
const cameraToDelete = ref('') as Ref<string>;
const deleteCameraModelFunc = ref<Function>(() => deleteCamera);
const registeredCameras = ref<client.Camera[]>([]);
const unregisteredCameras = ref<client.Camera[]>([]);
const isLoading = ref<boolean>(false);

const applicationStore = useApplicationStore();
const { adminMode } = storeToRefs(applicationStore);

const showNew = applicationStore.canUser(client.Entitlements.CREATE_CAMERA, applicationStore.activeOrganisation!);
const showUpdate = applicationStore.canUser(client.Entitlements.UPDATE_CAMERA, applicationStore.activeOrganisation!);
const showDelete = applicationStore.canUser(client.Entitlements.DELETE_CAMERA, applicationStore.activeOrganisation!);
const showRegister = applicationStore.canUser(client.Entitlements.REGISTER_CAMERA, applicationStore.activeOrganisation!);

async function closeDeleteConfirmModal(): Promise<void> {
  showDeleteCameraModel.value = false;
}

async function showDeleteConfirmModal(row: client.Camera, redraw: Function): Promise<void> {
  cameraToDelete.value = row.serialNumber;
  showDeleteCameraModel.value = true;
  deleteCameraModelFunc.value = async (): Promise<void> => {
    await deleteCamera(row);
    await redraw();
  };
}

async function fetchCameraLists(): Promise<client.Camera[]> {
  isLoading.value = true;

  try {
    // Fetch registered cameras specific to the active organisation
    const registeredRes = await client.listCameras({ organisation: applicationStore.activeOrganisation!.id });
    registeredCameras.value = registeredRes.data.filter(
      (camera: client.Camera) => camera.status === client.CameraStatus.REGISTERED
    );
  } catch (error) {
    console.error("Error fetching registered cameras:", error);
    registeredCameras.value = []; // Ensure it defaults to an empty array on error
  }

  try {
    // Attempt to fetch all unregistered cameras regardless of organisation
    const unregisteredRes = await client.listUnregisteredCameras();
    // Ensure unregisteredCameras is populated even if empty
    unregisteredCameras.value = unregisteredRes.data.filter(
      (camera: client.Camera) => camera.status === client.CameraStatus.UNREGISTERED
    );
  } catch (error) {
    // Extract and handle the error message using extractErrorMessage
    const errorMessage = extractErrorMessage(error);
    console.error("Error fetching unregistered cameras:", errorMessage);
    unregisteredCameras.value = []; // Default to an empty array on any error
  } finally {
    isLoading.value = false;
  }

  // Return registered cameras for the DirectoryTable
  return registeredCameras.value;
}

async function deleteCamera(row: client.Camera): Promise<void> {
  await client.deleteCameraById({ cameraId: row.id });

  await closeDeleteConfirmModal();

  applicationStore.publishSuccessNotification({
    text: 'Successfully deleted camera.',
    autoCloseMs: 3000,
  });

  // Refresh the camera list
  await reloadTable();
}

const columns: DirectoryTableColumn[] = [
  {
    label: TableHeaders.DeviceId,
    field: 'serialNumber',
    headerClasses: 'text--white-space-nowrap',
    width: '97%',
  },
  {
    label: TableHeaders.Status,
    field: 'status',
    headerClasses: 'status',
    width: '1%',
  },
  {
    label: TableHeaders.Actions,
    field: 'actions',
    headerClasses: 'status',
    width: '1%',
  },
];

// Insert SystemType after the DeviceId column if isAdmin
if (adminMode) {
  columns.splice(1, 0, {
    label: TableHeaders.SystemType,
    field: 'systemType',
    headerClasses: 'text--white-space-nowrap',
    width: '1%',
  });
}

const table = reactive({
  columns: columns,
  sortable: {
    order: 'id',
    sort: 'asc',
  },
});

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.Cameras, active: true },
];

// Hack to reload the table because we have to use <Suspense>
async function reloadTable(): Promise<void> {
    tableKey.value++;
}
</script>

<template>
  <SubHeader heading="Cameras"
             level="2">
    <template #buttons>
      <template v-if="isLoading" />
      <template v-else>
        <ButtonComponent v-if="adminMode"
                         :is-block-btn="true"
                         :to="{ name: PageNames.CameraNew }"
                         :variant="ButtonVariant.Dark"
                         :icon-position="IconPosition.Left"
                         :icon-name="IconName.PlusIcon"
                         :icon-style="IconStyle.Solid">
          Create Camera
        </ButtonComponent>
        <ButtonComponent v-if="showRegister"
                         :is-block-btn="true"
                         :to="{ name: PageNames.CameraRegister }"
                         :variant="ButtonVariant.Dark"
                         :icon-position="IconPosition.Left"
                         :icon-name="IconName.PlusIcon"
                         :icon-style="IconStyle.Solid">
          Register Camera
        </ButtonComponent>
        <ButtonComponent v-if="adminMode"
                         :is-block-btn="true"
                         :variant="ButtonVariant.Dark"
                         :to="{ name: PageNames.CameraUnregistered }"
                         :notification-count="unregisteredCameras.length"
                         :notification-count-variant="NotificationCountVariant.Danger">
          Unregistered Cameras
        </ButtonComponent>
      </template>
    </template>
  </SubHeader>

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <section>
          <DirectoryTable :key="tableKey"
                          :retrieve-data="fetchCameraLists"
                          :columns="table.columns"
                          :sortable="table.sortable">
            <template #table-empty>
              <EmptyState heading-text="No cameras found"
                          strap-line="Get started by registering your first camera"
                          :button-variant="ButtonVariant.Dark"
                          button-text="Register Camera"
                          :icon-name="IconName.CameraIcon"
                          :icon-style="IconStyle.Outline"
                          :to="{ name: PageNames.CameraRegister }" />
            </template>

            <template #cell="{ row, column }">
              <template v-if="column.label === TableHeaders.Status">
                <div class="d-flex justify-center">
                  <BadgeComponent v-if="row.status === CameraStatus.Registered"
                                  :is-pill="true"
                                  :variant="BadgeVariant.Success">
                    Registered
                  </BadgeComponent>
                  <BadgeComponent v-if="row.status === CameraStatus.Unregistered"
                                  :is-pill="true"
                                  :variant="BadgeVariant.Danger">
                    Unregistered
                  </BadgeComponent>
                </div>
              </template>

              <template v-if="column.label === TableHeaders.Actions">
                <div class="d-flex gap-20">
                  <ButtonComponent :to="{ name: PageNames.CameraStatus, params: { id: row.id } }"
                                   :icon-name="IconName.AdjustmentsHorizontalIcon"
                                   :icon-style="IconStyle.Outline"
                                   :size="ButtonSize.Small"
                                   :variant="ButtonVariant.Dark">
                    Status
                  </ButtonComponent>
                  <ButtonComponent v-if="showDelete || showUpdate"
                                   :icon-name="IconName.TrashIcon"
                                   :icon-style="IconStyle.Outline"
                                   :size="ButtonSize.Small"
                                   :variant="ButtonVariant.Danger"
                                   @click="showDeleteConfirmModal(row, fetchCameraLists)">
                    Delete
                  </ButtonComponent>
                </div>
              </template>
            </template>
          </DirectoryTable>
        </section>
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>

  <!-- Delete Camera Modal -->
  <ModalComponent :visible="showDeleteCameraModel"
                  :heading-title="`Delete Camera ${cameraToDelete}`"
                  @on-close="closeDeleteConfirmModal">
    <template #modal-content>
      <p>
        Are you sure you want to delete the camera: <span class="text--bold">{{ cameraToDelete }}</span>
      </p>
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="closeDeleteConfirmModal">
        Cancel
      </ButtonComponent>
      <ButtonComponent :is-block-btn="true"
                       :variant="ButtonVariant.Danger"
                       @click="deleteCameraModelFunc()">
        Delete
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>
