<script setup lang="ts">
import SubHeader from '@components/SubHeader.vue';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import { BreadcrumbPaths, BreadcrumbTitles } from '@viewModels/enums';

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllOrganisations, to: BreadcrumbPaths.Organisations },
  { title: BreadcrumbTitles.EditOrganisation, active: true },
];
</script>

<template>
  <SubHeader heading="Edit Organisation"
             level="2" />

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />
  <router-view />
</template>
