<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useApplicationStore } from '@stores/application';
import { IconName, IconStyle } from '@viewModels/heroIcons';
import { ButtonVariant } from '@viewModels/enums';
import ButtonComponent from '@components/ButtonComponent.vue';

const applicationStore = useApplicationStore();
const { currentNotification } = storeToRefs(applicationStore);

function closeNotification(): void {
  currentNotification.value = undefined;
}
</script>

<template>
  <Transition name="slide-fade">
    <div v-if="currentNotification" class="notification" :class="currentNotification?.class">
      <div class="notification-content">
        <p>{{ currentNotification?.text }}</p>
        <ButtonComponent aria-label="Close notification"
                         :icon-name="IconName.XCircleIcon"
                         :icon-style="IconStyle.Outline"
                         :is-outline-btn="true"
                         class="notification-close"
                         :variant="ButtonVariant.Link"
                         :is-icon-btn="true"
                         @click="closeNotification" />
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.notification {
  position: fixed;
  right: 20px;
  bottom: 85px;
  z-index: 9999;
  width: calc(100% - $gap-default * 2);
  padding: clamp($gap-mobile, 2vw, $gap-desktop);
  margin: 0 auto;
  color: $neutral-50;
  cursor: default;
  background: $neutral-800;
  border-radius: 10px;
  box-shadow: $box-shadow;
  opacity: 0.9;
  transition: opacity 0.3s ease-in-out;

  @media screen and (width >= $breakpoint-lg) {
    bottom: $gap-default;
    max-width: 450px;
  }

  &:hover {
    opacity: 1;
  }

  &.notification-success {
    color: $neutral-50;
    background: $green-800;
    border-color: $green-600;
  }

  &.notification-error {
    color: $neutral-50;
    background: $red-800;
    border-color: $red-400;
  }

  .notification-content {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 10px;
    align-items: center;

    p {
      margin: 0;
    }

    .notification-close {
      color: inherit;
      opacity: 0.9;
      transition: all 0.15s ease;

      &:hover,
      &:focus-visible {
        opacity: 1;
      }
    }
  }
}

/* Transition for sliding effect */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

.slide-fade-enter-to,
.slide-fade-leave-from {
  opacity: 1;
  transform: translateX(0);
}
</style>
