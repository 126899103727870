export function timeFormat(timezone?: string, locale?: string): Intl.DateTimeFormat {
    return new Intl.DateTimeFormat(locale ?? 'en-GB', {
        timeStyle: 'long',
        timeZone: timezone ?? 'Europe/London'
    });
}

export function dateFormat(timezone?: string, locale?: string): Intl.DateTimeFormat {
    return new Intl.DateTimeFormat(locale ?? 'en-GB', {
        dateStyle: 'short',
        timeZone: timezone ?? 'Europe/London'
    });
}

export function dateTimeFormat(timezone?: string, locale?: string): Intl.DateTimeFormat {
    return new Intl.DateTimeFormat(locale ?? 'en-GB', {
        dateStyle: 'short',
        timeStyle: 'long',
        timeZone: timezone ?? 'Europe/London'
    });
}
