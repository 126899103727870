import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

import { PageNames } from '@viewModels/enums';

export default function (to: RouteLocationNormalized): RouteLocationRaw | void {
  if (to.meta['public']) return;
  var system = import.meta.env['VITE_SYSTEM'] ?? 'HUB';
  if (system === 'HUB') return;

  const hubOnly = to.meta['hubOnly'] ?? false;
  if (!hubOnly) return;
  return { name: PageNames.NotFound };
}
