<script setup lang="ts">
import { Ref, ref } from 'vue';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import { useApplicationStore } from '@stores/application';
import { ButtonType, ButtonVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import ButtonActions from '@layouts/ButtonActions.vue';

const props = defineProps<{
  sendResetEmail: Function;
}>();

const emit = defineEmits<{
  (e: 'onSuccess'): void;
  (e: 'onBack'): void;
  (e: 'update:email', value: String): void;
}>();

const applicationStore = useApplicationStore();

const isSubmitting: Ref<boolean> = ref(false);

interface LoginWithEmailAndPasswordForm {
  email: string;
}

const schema = yup.object({
  email: yup.string().email().required(),
});

const { handleSubmit } = useForm<LoginWithEmailAndPasswordForm>({
  validationSchema: schema,
  initialValues: {
    email: applicationStore.activeUser?.email,
  },
});

const { value: emailValue, errorMessage: emailError } = useField<string>('email', 'email', {
  validateOnValueUpdate: false,
  modelPropName: 'email',
});

const onSubmit = handleSubmit(async (values) => {
  console.log('onSubmit');
  isSubmitting.value = true;
  try {
    await props.sendResetEmail(values.email);
    emit('onSuccess');
  } catch (error: any) {
    console.error(error);
    return;
  } finally {
    isSubmitting.value = false;
  }
});
</script>

<template>
  <form @submit="onSubmit">
    <section class="account-form--content">
      <div class="fields">
        <div class="field">
          <label for="email-address">Email address</label>
          <input id="email-address"
                 v-model="emailValue"
                 type="email"
                 autocomplete="email">
          <p class="message message-error">
            {{ emailError }}
          </p>
        </div>
      </div>
    </section>

    <ButtonActions>
      <ButtonComponent :disabled="isSubmitting"
                       :type="ButtonType.Submit"
                       :variant="ButtonVariant.Dark"
                       :is-block-btn="true">
        Send Reset Link
      </ButtonComponent>
    </ButtonActions>
  </form>
</template>
