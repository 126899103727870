<script setup lang="ts">
import { PropType, ref } from 'vue';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import * as client from '@gabrielcam/api-client';

import { ResourceViewModel } from '@viewModels/assignedRoleResourceViewModel';
import { ButtonType, ButtonVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import ModalComponent from '@components/ModalComponent.vue';

const emit = defineEmits<{
  (e: 'onClose'): void;
  (e: 'onSubmit', roleId: string, relId: string): void;
}>();
const props = defineProps({
  type: { type: String, required: true },
  resources: { type: Object as PropType<Array<ResourceViewModel>>, required: true },
  roles: { type: Object as PropType<Array<client.Role>>, required: true },
});

interface UserAssignRoleForm {
  roleId: string;
  relId: string;
}
const schema = yup.object({
  roleId: yup.string().required(),
  relId: yup.string().required(),
});
const { handleSubmit } = useForm<UserAssignRoleForm>({
  validationSchema: schema,
});

// const route = useRoute();

// const userId: string = route.params['id'] as string;
// const isSubmitting = ref<boolean>(false);
const filteredViewRoles = ref<client.Role[]>(props.roles);
const filteredResources = ref<ResourceViewModel[]>(props.resources);

const { value: roleIdValue, errorMessage: roleIdError } = useField<string>('roleId', 'roleId');
const { value: relIdValue, errorMessage: relIdError } = useField<string>('relId', 'relId');

const onSubmit = handleSubmit(async (values: any) => {
  emit('onClose');
  emit('onSubmit', values.roleId, values.relId);
  // isSubmitting.value = true;
  // if (applicationStore.activeOrganisation == undefined) return;

  // try {
  //   await client.assignRole(userId, { id: values.roleId, relId: values.relId, organisation: applicationStore.activeOrganisation!.id });
  //   applicationStore.publishSuccessNotification({
  //     text: 'Successfully added role.',
  //     autoCloseMs: 3000,
  //   });

  // } catch (error) {
  //   if (error instanceof client.ApiError) {
  //     // @ts-ignore
  //     applicationStore.publishErrorNotification({ text: error.body.error.message });
  //   } else {
  //     applicationStore.publishErrorNotification({ text: 'UNKNOWN ERROR' });
  //   }
  // } finally {
  //   isSubmitting.value = false;
  // }
});

function filterViews(search: string): void {
  filteredResources.value = props.resources.filter((x) => {
    if (!!search.length) return true;
    return x.name.toLowerCase().includes(search.toLowerCase());
  });
}
function filterRoles(search: string): void {
  filteredViewRoles.value = props.roles.filter((x) => {
    if (!!search.length) return true;
    return x.shortName.toLowerCase().includes(search.toLowerCase());
  });
}
</script>

<template>
  <ModalComponent :visible="true"
                  :heading-title="`Add ${props.type.charAt(0).toUpperCase() + props.type.slice(1)} Role`"
                  @on-close="emit('onClose')">
    <template #modal-content>
      <form @submit="onSubmit">
        <div class="field-group">
          <div class="fields">
            <div class="field">
              <label for="name">{{ props.type }}</label>
              <v-select v-model="relIdValue"
                        label="name"
                        :options="filteredResources"
                        :reduce="(resource: ResourceViewModel) => resource.id"
                        @search="filterViews" />
              <div class="message message-error">
                {{ relIdError }}
              </div>
            </div>

            <div class="field">
              <label for="role">Role</label>
              <v-select v-model="roleIdValue"
                        label="label"
                        :options="filteredViewRoles"
                        :reduce="(role: client.Role) => role.id"
                        @search="filterRoles" />
              <div class="message message-error">
                {{ roleIdError }}
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #modal-footer>
      <ButtonComponent :is-outline-btn="true"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="emit('onClose')">
        Cancel
      </ButtonComponent>
      <ButtonComponent :type="ButtonType.Submit"
                       :is-block-btn="true"
                       :variant="ButtonVariant.Dark"
                       @click="onSubmit">
        Save
      </ButtonComponent>
    </template>
  </ModalComponent>
</template>
