import { reactive } from 'vue';

/**
 * Reactive state object for mobile detection.
 */
const state = reactive({
  isMobile: window.innerWidth <= 960,
});

const updateIsMobile = (): void => {
  state.isMobile = window.innerWidth <= 960;
};

/**
 * Returns an object with the current mobile detection state and a function to update it.
 * @returns {{ isMobile: boolean; updateIsMobile: () => void }} The mobile detection state and update function.
 */
export function useMobileDetection(): { isMobile: boolean; updateIsMobile: () => void } {
  return {
    isMobile: state.isMobile,
    updateIsMobile,
  };
}

export { state as isMobileState, updateIsMobile };
