import { getActivePinia, Pinia, Store } from 'pinia';

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>;
}

export function resetAllStores() {
  const pinia = getActivePinia() as ExtendedPinia | undefined;

  if (!pinia) throw new Error('No stores configured');

  pinia._s.forEach((store) => {
    store.$dispose();
    delete pinia.state.value[store.$id];
  });

  localStorage.clear();
  return;
}
