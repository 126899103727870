<script lang="ts" setup>
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import * as client from '@gabrielcam/api-client';
import { useApplicationStore } from '@stores/application';
import { useViewStore } from '@stores/view';

import { BreadcrumbPaths, BreadcrumbTitles, ButtonVariant, PageNames } from '@viewModels/enums';
import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import SubHeader from '@components/SubHeader.vue';

const viewStore = useViewStore();
const applicationStore = useApplicationStore();

const { datePickerIsOpen } = storeToRefs(viewStore);

const showNew = applicationStore.canUser(client.Entitlements.CREATE_SEQUENCE, applicationStore.activeOrganisation!);
const view = ref<{ name?: string }>({});

const route = useRoute();
const pageName = ref<PageNames>(route.name as PageNames);
const cameraId = route.params['id'] as string;
const viewId: string = route.params['id'] as string;

const isDataReady = ref(false);

onBeforeMount(async () => {
  view.value = await client.getViewById({ viewId });
  isDataReady.value = true;
});

// Watch the route name and update pageName
watch(
  () => route.name,
  (newRouteName) => {
    pageName.value = newRouteName as PageNames;
  },
);

function toggleDatePicker(): void {
  datePickerIsOpen.value = !datePickerIsOpen.value;
}

/**
 * Computed property to generate the heading content based on the current page and user login status.
 *
 * @returns {string} The generated heading content.
 */
const headingContent = computed(() => {
  let content = '';

  // If user is logged in
  if (applicationStore.activeUser) {
    if (view.value.name) {
      content += `${view.value.name}`;
    }

    if (pageName.value) {
      if (pageName.value === PageNames.ViewViewer) {
        content += ` ${BreadcrumbTitles.Viewer}`;
      } else if (pageName.value === PageNames.ViewVideos) {
        content += ` ${BreadcrumbTitles.Videos}`;
      } else if (pageName.value === PageNames.ViewDownloads) {
        content += ` ${BreadcrumbTitles.Downloads}`;
      } else if (pageName.value === PageNames.ViewGallery) {
        content += ` ${BreadcrumbTitles.Gallery}`;
      }
    }
  } else {
    content = view.value.name || '';
  }
  return content.trim();
});

// Dynamic breadcrumbs
const breadcrumbs = ref<BreadCrumbItem[]>([]);
const breadcrumbConfig = {
  [PageNames.ViewViewer]: [
    { title: BreadcrumbTitles.Views, to: BreadcrumbPaths.Views },
    { title: '', active: true },
    { title: BreadcrumbTitles.Viewer, active: true },
  ],
  [PageNames.ViewVideos]: [
    { title: BreadcrumbTitles.AllVideos, to: BreadcrumbPaths.Videos },
    { title: '', active: true },
    { title: BreadcrumbTitles.Videos, active: true },
  ],
  [PageNames.ViewGallery]: [
    { title: BreadcrumbTitles.Views, to: BreadcrumbPaths.Views },
    { title: '', active: true },
    { title: BreadcrumbTitles.Gallery, active: true },
  ],
  [PageNames.ViewDownloads]: [
    { title: BreadcrumbTitles.Views, to: BreadcrumbPaths.Views },
    { title: '', active: true },
    { title: BreadcrumbTitles.Downloads, active: true },
  ],
};

/**
 * Watch for changes to the view name and update the breadcrumbs accordingly.
 */
watch(
  () => view.value.name,
  (newName) => {
    if (newName) {
      // Get the breadcrumb configuration for the current page
      const config = breadcrumbConfig[pageName.value as keyof typeof breadcrumbConfig] as BreadCrumbItem[] || [{
        title: BreadcrumbTitles.Views,
        to: BreadcrumbPaths.Views,
      }];
      // map breadcrumb configuration with updated title based on view name and user login status
      breadcrumbs.value = applicationStore.activeUser
        ? config.map((item) => ({ ...item, title: item.title || newName }))
        : [{ title: newName, active: true }];
    }
  },
);
</script>

<template>
  <SubHeader :heading="headingContent"
             level="2">
    <template #buttons>
      <template v-if="pageName === PageNames.ViewViewer">
        <div class="d-lg-none w-100">
          <ButtonComponent :is-block-btn="true"
                           :variant="ButtonVariant.Dark"
                           @click="toggleDatePicker">
            Open Calendar
          </ButtonComponent>
        </div>
      </template>
      <template v-if="pageName === PageNames.ViewVideos && showNew">
        <ButtonComponent :is-block-btn="true"
                         :to="{ name: PageNames.VideoNew, params: { viewId: cameraId } }"
                         :variant="ButtonVariant.Dark">
          New Video
        </ButtonComponent>
      </template>
    </template>
  </SubHeader>

  <Breadcrumb v-if="isDataReady"
              :is-sticky="true"
              :items="breadcrumbs" />

  <router-view />
</template>
