<script lang="ts" setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';

import { BreadcrumbPaths, BreadcrumbTitles } from '@viewModels/enums';

import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import VideoCreationForm from '@components/sequencer/VideoCreationForm.vue';
import SubHeader from '@components/SubHeader.vue';

const route = useRoute();
const viewId = ref<string>();

viewId.value = route.params['viewId'] as string | undefined;

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.Videos, to: BreadcrumbPaths.Videos },
  { title: BreadcrumbTitles.CreateVideo, active: true },
];
</script>

<template>
  <SubHeader heading="Create Video"
             level="2" />

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <VideoCreationForm :view-id="viewId" />
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>
</template>
