/**
 * Reduces the stats dataset by selecting the first, last, and evenly spaced points in between.
 * This function aims to retain roughly 20% of the original dataset size, ensuring that
 * the first and last elements are always included and the remaining points are evenly
 * distributed.
 */
export function reduceData<T>(dataset: T[]): T[] {
  if (dataset.length <= 3) {
    // If the dataset has 3 or fewer points, return it as is, this is for the past hour view.
    return dataset;
  }

  // Define the reduction percentage
  const reductionPercentage = 20;

  // Calculate the number of data points to keep
  const keepCount = Math.ceil(dataset.length * (reductionPercentage / 100));

  // Calculate the step size to evenly distribute the kept data points
  const step = (dataset.length - 1) / (keepCount - 1);

  // Initialize the reduced dataset array with the first element
  const reducedDataset: T[] = [dataset[0] as T];

  // Loop through the dataset and select the appropriate data points, excluding the first and last points
  for (let i = 1; i < keepCount - 1; i++) {
    const index = Math.round(i * step);
    reducedDataset.push(dataset[index] as T);
  }

  // Ensure the last element is included
  reducedDataset.push(dataset[dataset.length - 1] as T);

  return reducedDataset;
}
