<script setup lang="ts">
import { BreadcrumbPaths, BreadcrumbTitles } from '@viewModels/enums';

import Breadcrumb, { BreadCrumbItem } from '@components/Breadcrumb.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import ClientProjects from '@components/client/ClientProjects.vue';
import ClientForm from '@components/client/ClientUpdateForm.vue';
import Loading from '@components/Loading.vue';
import SubHeader from '@components/SubHeader.vue';

const breadcrumbs: BreadCrumbItem[] = [
  { title: BreadcrumbTitles.AllClients, to: BreadcrumbPaths.Clients },
  { title: BreadcrumbTitles.UpdateClient, active: true },
];
</script>

<template>
  <SubHeader heading="Update Client"
             level="2" />

  <Breadcrumb :is-sticky="true"
              :items="breadcrumbs" />

  <ContainerCard>
    <Suspense>
      <template #default>
        <ClientForm />
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
    <Suspense>
      <template #default>
        <ClientProjects />
      </template>

      <template #fallback>
        <Loading />
      </template>
    </Suspense>
  </ContainerCard>
</template>
