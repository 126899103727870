<script lang="ts" setup>
import { computed } from 'vue';

interface HeadingProps {
  level: '1' | '2' | '3' | '4' | '5' | '6';
  hasBottomMargin?: boolean;
  anchorId?: string | null;
}

const props = withDefaults(defineProps<HeadingProps>(), {
  hasBottomMargin: false,
  anchorId: null,
});

const tag = computed(() => `h${props.level}` as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6');
</script>

<template>
  <component :is="tag"
             :id="props.anchorId ? `#${props.anchorId}` : null"
             :class="{ 'mb-20': props.hasBottomMargin }">
    <slot />
  </component>
</template>

<style lang="scss" scoped>
h1 {
  font-size: clamp(1.5rem, 2vw, 2rem);
}

h2 {
  font-size: clamp(1.25rem, 2vw, 1.75rem);
}

h3 {
  font-size: clamp(1.125rem, 2vw, 1.5rem);
}

h4 {
  font-size: clamp(1rem, 2vw, 1.25rem);
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.875rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  text-wrap: balance;
}
</style>
