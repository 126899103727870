import * as client from '@gabrielcam/api-client';

export interface Access {
  [key: string]: string[] | undefined;
}

export interface BaseModel {
  id: string;
  object: client.Resources;
  organisation?: string;
  client?: string;
  project?: string;
  camera?: string;
}

function idIsInArray(id: string, array: Array<string>) {
  return array.some((e) => String(id) == String(e));
}

/**
 * Returns a list of resources a user can action grouped by resource type
 * @param user - The user with populated entitlements entitlements
 * @param action - The entitlement being requested
 * @param populateUpwards - When specifcied, populates the accessible resources with the parents of the resource
 * @returns An object containing the IDs of the resources where the user has the requested action
 */
function getResourcesForActionByType(roles: client.AssignedRole[], action: client.Entitlements): Access {
  const filteredRoles = roles.filter((e) => e.entitlements!.includes(action));

  const access: Access = {};
  for (const e of filteredRoles) {
    access[e.relType!] ??= [];
    access[e.relType!]!.push(e.relId!);
  }

  return access;
}

export function can(roles: client.AssignedRole[], action: client.Entitlements, doc: BaseModel) {
  const r = getResourcesForActionByType(roles, action);

  // first check if the document ID is explicitly listed in the access
  if (r[doc.object] && idIsInArray(doc.id, r[doc.object]!)) return true;

  // second check, if the documents parent organisation is explicitly listed in the rights
  if (doc.organisation && r['organisation'] && idIsInArray(doc.organisation, r['organisation'])) return true;

  // third check, if the documents parent client is explicitly listed in the rights
  if (doc.client && r['client'] && idIsInArray(doc.client, r['client'])) return true;

  // fourth check, if the documents parent project is explicitly listed in the rights
  if (doc.project && r['project'] && idIsInArray(doc.project, r['project'])) return true;

  // specific check for cameras which have permissions on views
  if (doc.camera && r['camera'] && idIsInArray(doc.camera, r['camera'])) return true;

  return false;
}
